import React from "react";
import {Typography} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {PD_LG} from "../../shared/dimens";
import {BaseWidget} from "./BaseWidget";

dayjs.extend(utc);

export class ClockWidget extends BaseWidget {

  protected renderContent(content?: {}): React.ReactElement {
    const now = dayjs.utc();//.add(content.timezone * 1000);
    return <div style={{padding: PD_LG}}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "center",
      }}>
        {/*<Typography style={{fontSize: height * 0.04}}>{this.state.content.location}</Typography>*/}
        <Typography style={{fontSize: "1.2em", fontWeight: "bold"}}>{"San Francisco"}</Typography>
        <div style={{display: "flex", alignItems: "baseline", justifyContent: "center", flexGrow: 1}}>
          <Typography style={{fontSize: "4.5em", fontWeight: "bold"}}>{now.format("hh:mm")}</Typography>
          <Typography style={{fontSize: "1.5em"}}>{now.format("A")}</Typography>
        </div>
        <span style={{fontSize: "1.2em"}}>{now.format("ddd, MMM D")}</span>
      </div>
    </div>;
  }
}