import {JsonObject} from "../../shared/json/json-object";
import {JsonProperty} from "../../shared/json/json-property";
import {BaseListItem, BaseListItemsLoader} from "../../shared/types";
import {BaseWidget} from "../widgets/BaseWidget";
import {Type} from "../../shared/json/helpers";

@JsonObject()
export class WidgetSpec extends BaseListItem {

  @JsonProperty()
  title: string;

  @JsonProperty()
  height: number;

  @JsonProperty()
  icon: string;

  @JsonProperty()
  customRendererId: string;

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}

export class WidgetSpecs extends BaseListItemsLoader<WidgetSpec> {

  private static instance: WidgetSpecs;

  static getInstance(): WidgetSpecs {
    if (!this.instance) {
      this.instance = new WidgetSpecs();
    }
    return this.instance;
  }

  constructor() {
    super({shared: true});
  }

  protected basePath(): string {
    return "widget_specs";
  }

  protected deserializeItem(value: any): WidgetSpec {
    return undefined;
  }

  protected serializeItem(item: WidgetSpec): any {
  }

  protected sortOrder(item1: WidgetSpec, item2: WidgetSpec): number {
    return 0;
  }
}

export class SystemWidgets {

  private static instance: SystemWidgets;

  static getInstance(): SystemWidgets {
    if (!this.instance) {
      this.instance = new SystemWidgets();
    }
    return this.instance;
  }

  private constructor() {
  }

  private readonly map = new Map<string, Type<BaseWidget>>();

  register<T extends BaseWidget>(id: string, widget: Type<T>): void {
    this.map.set(id, widget);
  }

  get(id: string): Type<BaseWidget> | null {
    return this.map.get(id);
  }
}