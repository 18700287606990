import React, {ReactElement} from 'react';
import {PathComponent} from "../index";
import {AppProps, FyneappsApp, FyneappsMain} from "../fyneapps-shared/FyneappsApp";
import {MainDashFragment} from "./dash/MainDashFragment";
import {SystemWidgets} from "./dash/types";
import {WeatherWidget} from "./widgets/WeatherWidget";
import {ClockWidget} from "./widgets/ClockWidget";
import {QuotesWidget} from "./widgets/quotes/QuotesWidget";
import {JokesWidget} from "./widgets/jokes/JokesWidget";
import {AppConfig} from "../shared/BaseApp";
import {LoginConfig} from "../shared/Login";

class Main extends FyneappsMain {

  protected renderMainContent(): React.ReactElement {
    return <MainDashFragment path={this.props.path}/>;
  }
}

export class App extends FyneappsApp {

  constructor(props: AppProps, context: any) {
    super(props, context);
    const systemWidgets = SystemWidgets.getInstance();
    systemWidgets.register("weather", WeatherWidget);
    systemWidgets.register("clock", ClockWidget);
    systemWidgets.register("quotes", QuotesWidget);
    systemWidgets.register("jokes", JokesWidget);
  }

  onCreateAppConfig(): AppConfig {
    return {
      ...super.onCreateAppConfig(),
      stampHeight: 144,
      stampText: null,
    };
  }

  protected getLoginConfig(): LoginConfig | null | undefined {
    const loginConfig = super.getLoginConfig();
    return {
      ...loginConfig,
      provisioning: {
        ...loginConfig?.provisioning,
        signupDisabled: true,
      },
    };
  }

  protected getAppName(): string {
    return "Dash";
  }

  protected getIconName(): string {
    return "/icon.png";
  }

  static nestedPaths(): PathComponent[] {
    return Main.nestedPaths();
  }

  protected renderMain(): ReactElement {
    return <Main path={this.props.path} noChrome/>;
  }
}