import {PluginConfig, PluginManifest} from "./types";
import {JSON_OBJECT} from "./json/helpers";
import {JsonObject} from "./json/json-object";
import {JsonProperty} from "./json/json-property";
import {BaseApp} from "./BaseApp";

@JsonObject()
export class PluginStoreLayout {

  @JsonProperty()
  sections: PluginsStoreSection[];
}

@JsonObject()
export class PluginsStoreSection {

  @JsonProperty()
  sectionId: string;

  @JsonProperty()
  title: string;

  @JsonProperty()
  items: PluginsStoreSectionItem[];
}

@JsonObject()
export class PluginsStoreSectionItem {

  @JsonProperty()
  sectionItemId: string;

  @JsonProperty()
  title: string;

  @JsonProperty()
  path: string;
}

@JsonObject()
export class PluginStoreListLayout {

  @JsonProperty()
  listSections: PluginsStoreListSection[];
}

@JsonObject()
export class PluginsStoreListSection {

  @JsonProperty()
  items: PluginsStoreListSectionItem[];
}

@JsonObject()
export class PluginsStoreListSectionItem {

  pluginManifest: PluginManifest;

  @JsonProperty()
  pluginUrl: string;
}

@JsonObject()
export class PluginStoreConfig {

  @JsonProperty()
  layout: PluginStoreLayout;

  @JsonProperty()
  listLayoutMap: Map<string, PluginStoreListLayout>;
}

@JsonObject()
export class SystemPluginsConfig {

  @JsonProperty()
  pluginUrls: string[] = [];
}

export type Plugin = {
  url: string,
  manifest: PluginManifest,
  config: PluginConfig,
}

export interface PluginConfigProvider {

  getPluginConfig(): PluginConfig;
}

export class Plugins {

  private static instance: Plugins;

  static initInstance(pluginUrls: string[], configProvider: PluginConfigProvider): Plugins {
    this.instance = new Plugins(BaseApp.CONTEXT.getAppConfig()?.systemPluginsConfig, pluginUrls, configProvider);
    return this.instance;
  }

  static getInstance() {
    return this.instance;
  }

  private readonly manifests = PluginManifests.getInstance();

  private plugins: Plugin[];

  private constructor(private readonly systemConfig: SystemPluginsConfig, private readonly pluginUrls: string[], private readonly configProvider: PluginConfigProvider) {
  }

  async loadPlugins(): Promise<void> {
    this.plugins = [];
    if (this.systemConfig?.pluginUrls) {
      for (const pluginUrl of this.systemConfig?.pluginUrls) {
        await this.addPlugin(pluginUrl);
      }
    }
    for (const pluginUrl of this.pluginUrls) {
      await this.addPlugin(pluginUrl);
    }
  }

  private async addPlugin(pluginUrl: string) {
    const pluginManifest = await this.manifests.getOrLoadPluginManifest(pluginUrl);
    if (!pluginManifest) {
      console.log("not loaded: " +pluginUrl);
      return;
    }
    this.plugins.push({
      url: pluginUrl,
      manifest: pluginManifest,
      config: this.configProvider.getPluginConfig(),
    } as Plugin);
  }

  async getOrLoadPlugins(): Promise<Plugin[]> {
    if (!this.plugins) {
      await this.loadPlugins();
    }
    return Promise.resolve(this.plugins || []);
  }

  removePluginUrl(pluginUrl: string) {
  }

  getPlugins(): Plugin[] {
    return this.plugins;
  }
}

export class PluginManifests {

  private static instance = new PluginManifests();

  static getInstance(): PluginManifests {
    return this.instance;
  }

  private readonly cache = new Map<string, PluginManifest>();

  async loadPluginManifest(pluginUrl: string): Promise<void> {
    let url = pluginUrl;
    while (url.endsWith("/")) {
      url = url.substring(0, url.length - 1);
    }
    await fetch(url + "/plugin.json", {cache: "no-cache"})
      .then(resp => resp.json())
      .then(json => {
          const pluginManifest = JSON_OBJECT.deserializeObject(json, PluginManifest);
          pluginManifest.pluginUrl = pluginUrl;
          this.cache.set(pluginUrl, pluginManifest);
        }
      ).catch((reason) => console.log("Unable to load plugin: " + pluginUrl + "; " + reason));
  }

  async getOrLoadPluginManifest(pluginUrl: string): Promise<PluginManifest> {
    if (!this.cache.has(pluginUrl)) {
      await this.loadPluginManifest(pluginUrl);
    }
    return this.cache.get(pluginUrl);
  }
}
