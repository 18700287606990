import {AppPrefListener} from "./types";
import {Prefs} from "./prefs";
import {User} from "./entities";
import {JSON_OBJECT} from "./json/helpers";
import {v4 as uuid} from "uuid";

export abstract class BaseAppPrefs {
  
  static readonly PREF_USER = "user";
  static readonly PREF_PASSWORD = "password";
  static readonly PREF_PLUGIN_URLS = "plugin_urls";

  // Internal
  private static readonly PREF_CLIENT_ID = "client_id";

  private readonly listenersMap = new Map<string, Array<WeakRef<AppPrefListener>>>();

  protected readonly prefs = new Prefs();
  private user?: User;

  protected constructor() {
  }

  addListener(key: string, listener: AppPrefListener) {
    let listeners = this.listenersMap.get(key);
    if (!listeners) {
      listeners = [];
      this.listenersMap.set(key, listeners);
    }
    listeners.push(new WeakRef(listener));
  }

  removeListener(key: string, listener: AppPrefListener) {
    let listeners = this.listenersMap.get(key);
    if (listeners) {
      const index = listeners.findIndex(ref => ref.deref() === listener);
      if (index >= 0) {
        listeners.splice(index, 1);
      }
    }
  }

  clear() {
    this.prefs.clear();
  }

  isDev(): boolean {
    return false;
  }

  hasAuthCredentials(): boolean {
    return this.getUsername()?.length > 0 && this.getPassword()?.length > 0;
  }

  getUsername(): string {
    return this.getUser()?.uid;
  }

  getPassword(): string {
    return this.prefs.getString(BaseAppPrefs.PREF_PASSWORD);
  }

  getUser(): User {
    if (!this.user) {
      this.user = JSON_OBJECT.deserializeObject(JSON.parse(this.prefs.getString(BaseAppPrefs.PREF_USER)), User);
    }
    return this.user;
  }

  setUserAuth(user: User, password: string) {
    if (user && password) {
      this.prefs.setString(BaseAppPrefs.PREF_USER, JSON.stringify(user));
      this.prefs.setString(BaseAppPrefs.PREF_PASSWORD, password);
    } else {
      this.prefs.remove(BaseAppPrefs.PREF_USER);
      this.prefs.remove(BaseAppPrefs.PREF_PASSWORD);
    }
    this.user = null;
  }

  getClientId(): string {
    let clientId;
    if ((clientId = this.prefs.getString(BaseAppPrefs.PREF_CLIENT_ID)) === null) {
      this.prefs.setString(BaseAppPrefs.PREF_CLIENT_ID, clientId = uuid());
    }
    return clientId;
  }

  getPluginUrls(): string[] {
    const string = this.prefs.getString(BaseAppPrefs.PREF_PLUGIN_URLS);
    if (!string) {
      return [];
    }
    const urls: string[] = JSON.parse(string);
    return urls;
  }

  hasPluginUrl(url: string) {
    const urls: string[] = this.getPluginUrls();
    const index = urls.findIndex(u => u === url);
    return index >= 0;
  }

  addPluginUrl(url: string) {
    const urls: string[] = this.getPluginUrls();
    const index = urls.findIndex(u => u === url);
    if (index < 0) {
      urls.push(url);
      this.prefs.setString(BaseAppPrefs.PREF_PLUGIN_URLS, JSON.stringify(urls));
    }
  }

  removePluginUrl(url: string) {
    const urls: string[] = this.getPluginUrls();
    const index = urls.findIndex(u => u === url);
    if (index >= 0) {
      urls.splice(index, 1);
      this.prefs.setString(BaseAppPrefs.PREF_PLUGIN_URLS, JSON.stringify(urls));
    }
  }

  protected notify(key: string) {
    const listeners = this.listenersMap.get(key);
    listeners?.forEach(ref => ref.deref()?.onPrefChanged(key));
  }
}