import {PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import React from "react";
import {ButtonBase, Card, IconButton} from "@mui/material";
import {BaseFragment} from "../../shared/BaseFragment";
import {WidgetSpec} from "./types";
import {StyledBoxRow, StyledSpan} from "../../shared/StyledComponents";
import {SZ_XSM} from "../../shared/dimens";
import {AppsOutlined} from "@mui/icons-material";
import {AppsFragment, AppsListener} from "./AppsFragment";
import {WidgetsFragment} from "./WidgetsFragment";
import {THEME_COLOR_PRIMARY} from "../../fyneapps-shared/consts";
import fyneappsLogotype from "../../fyneapps-shared/res/fyneapps_logotype.png";
import {BaseApp} from "../../shared/BaseApp";
import {UserFragment} from "../../shared/UserFragment";
import {UserCache, UserProfilePhoto} from "../../shared/types";
import {getAuth} from "@firebase/auth";

export type MainDashFragmentProps = PageFragmentProps & {}

enum Mode {
  WIDGETS,
  APPS,
}

type MainDashFragmentState = PageFragmentState & {
  specs: WidgetSpec[],
  mode: Mode,
}

export class MainDashFragment extends BaseFragment<MainDashFragmentProps, MainDashFragmentState> implements AppsListener {

  private readonly auth = getAuth();

  protected onCreateState(): MainDashFragmentState {
    return {
      ...super.onCreateState(),
      mode: Mode.WIDGETS,
    };
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <div style={{
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      backgroundImage: "linear-gradient(120deg, #166d3b 0%, " + THEME_COLOR_PRIMARY + " 74%)"
    }}>
      <StyledBoxRow style={{
        flexGrow: 0,
        flexShrink: 0,
        height: 24,
        padding: 8,
        background: THEME_COLOR_PRIMARY,
        position: "relative"
      }}>
        <div style={{background: "white", opacity: 0.05, position: "absolute", left: 0, top: 0, right: 0, bottom: 0}}/>
        <img src={fyneappsLogotype} style={{filter: "invert(1)", height: "120%"}}/>
        <StyledSpan/>
        <IconButton onClick={() => this.setState({mode: Mode.APPS})}>
          <AppsOutlined style={{color: "white"}}/>
        </IconButton>
        <Card style={{width: SZ_XSM, height: SZ_XSM, flexShrink: 0}}>
          <ButtonBase
            onClick={() => BaseApp.CONTEXT.showDialog(
              null,
              () => <UserFragment uid={this.auth.currentUser.uid}/>)}>
            <img src={UserProfilePhoto(UserCache.getInstance().getCachedUser(this.auth.currentUser.uid))}
                 style={{width: "100%", height: "100%"}}/>
          </ButtonBase>
        </Card>
      </StyledBoxRow>
      <div style={{display: "flex", flexDirection: "column", flexGrow: 1, overflowY: "scroll"}}>
        {this.renderContent()}
      </div>
    </div>;
  }

  private renderContent(): React.ReactElement {
    switch (this.state.mode) {
      case Mode.WIDGETS:
        return <WidgetsFragment path={this.props.path}/>;
      case Mode.APPS:
        return <AppsFragment listener={this}/>;
    }
  }

  onHide() {
    this.setState({mode: Mode.WIDGETS});
  }
}