import {JsonObject} from "../json/json-object";
import {JsonProperty} from "../json/json-property";
import {Members} from "../entities";
import {$KTS, BaseListItem, BaseListItemsLoader, DefaultObjectLoader, KeyTextStrings, TypedObject} from "../types";
import {JSON_OBJECT, Type} from "shared/json/helpers";
import {md5, md5_uuid} from "../md5";
import {FormGen} from "../formgen";
import {ProvisioningContext, Rel} from "../database";
import {passkey, PasskeyConfig} from "../passkey";
import {PROVISIONING_CODE_MAX_LENGTH} from "../constants";
import {getMemberAuth} from "../auth";

// Concepts
// User - An employee
// Group - A group of Users
// Permission - A condition to allow/disallow an action
// Role - A group of permissions
// User.Permission (stored as User.permissionIds) - A User with a Permission
// User.Role (stored as User.roleIds)- A User in a Role
// User.Group (stored as User.groupIds)- A User who is member of a Group
// Group.Role (stored as Group.roleIds) - A Group with automatic membership for Users in Role

@JsonObject()
abstract class DirectoryObject extends TypedObject {

  constructor() {
    super(null, null);
  }
}

class DirectoryObjectLoader<T extends DirectoryObject> extends DefaultObjectLoader<T> {

  constructor(basePath: string, id: string, type: Type<T>) {
    super(basePath, id, type, {shared: true});
  }
}

@JsonObject()
abstract class DirectoryListItem extends BaseListItem {

  constructor(id: string) {
    super(id, null, null);
  }
}

abstract class DirectoryListItemLoader<T extends DirectoryListItem> extends BaseListItemsLoader<T> {

  protected constructor() {
    super({shared: true});
  }

  protected basePath(): string {
    return "directory/" + this.directoryPath();
  }

  protected abstract directoryPath(): string;
}

export enum DirectoryOrgUnitType {
  DENTAL_PRACTICE = "dental_practice",
  OTHER = "other",
}

export const DIRECTORY_ORG_UNIT_TYPE_KTS = new KeyTextStrings([
  $KTS(DirectoryOrgUnitType.DENTAL_PRACTICE, "Dental Practice"),
  $KTS(DirectoryOrgUnitType.OTHER, "Other"),
]);

@JsonObject()
export class DirectoryOrgUnitRoot extends TypedObject {

  @JsonProperty()
  readonly id: string = "orgunitroot";

  @JsonProperty()
  @FormGen({name: "Type", type: "enum", enumValues: DIRECTORY_ORG_UNIT_TYPE_KTS.values, noneditable: true})
  type: string;

  @JsonProperty()
  @FormGen({name: "Name", type: "string"})
  name: string;

  @JsonProperty()
  @FormGen({
    name: "Logo",
    type: "file",
    fileMimeTypes: ["image/jpg", "image/png"],
    description: "Logo for your organization"
  })
  logo: string;

  @JsonProperty()
  @FormGen({name: "Description", type: "string"})
  description: string;

  @JsonProperty()
  @FormGen({name: "Country", type: "country_select"})
  country: string;

  @JsonProperty()
  @FormGen({name: "Phone number", type: "string"})
  phoneNumber: string;

  constructor(creator: string, created: number, private readonly overrideProvisioningContext?: ProvisioningContext) {
    super(creator, created);
  }

  protected basePath(): string | null {
    return "directory";
  }

  static async loadWithProvisioningContext(provisioningContext: ProvisioningContext) {
    const obj = new DirectoryOrgUnitRoot(null, null, provisioningContext);
    await obj.load();
    return obj;
  }

  createDefaultLoader(): DefaultObjectLoader<any> {
    return new DefaultObjectLoader<any>(this.basePath(), this.id, this.getType(), {
      shared: true,
      overrideProvisioningContext: this.overrideProvisioningContext
    });
  }

  protected getType(): Type<any> {
    return DirectoryOrgUnitRoot;
  }
}

@JsonObject()
export class DirectoryOrgUnit extends DirectoryListItem {

  @JsonProperty()
  @FormGen({name: "Type", type: "enum", enumValues: DIRECTORY_ORG_UNIT_TYPE_KTS.values})
  type: string;

  @JsonProperty()
  parentId: string;

  @JsonProperty()
  name: string;

  @JsonProperty()
  description: string;

  static createNew(): DirectoryOrgUnit {
    return new DirectoryOrgUnit(md5_uuid());
  }
}

export class DirectoryOrgUnits extends DirectoryListItemLoader<DirectoryOrgUnit> {

  private static instance: DirectoryOrgUnits;

  static getInstance() {
    if (!this.instance) {
      this.instance = new DirectoryOrgUnits();
    }
    return this.instance;
  }

  protected directoryPath(): string {
    return "orgunits";
  }

  protected deserializeItem(value: any): DirectoryOrgUnit {
    return JSON_OBJECT.deserializeObject(value, DirectoryOrgUnit);
  }

  protected serializeItem(item: DirectoryOrgUnit): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: DirectoryOrgUnit, item2: DirectoryOrgUnit): number {
    return 0;
  }
}

export enum DirectoryOfficeLocationType {
  BUILDING = "building",
  VIRTUAL = "virtual",
  OTHER = "other",
}

export const DIRECTORY_OFFICE_LOCATION_TYPE_KTS = new KeyTextStrings([
  $KTS(DirectoryOfficeLocationType.BUILDING, "Building"),
  $KTS(DirectoryOfficeLocationType.VIRTUAL, "Virtual"),
  $KTS(DirectoryOfficeLocationType.OTHER, "Other"),
]);

@JsonObject()
export class DirectoryOfficeLocation extends DirectoryListItem {

  @JsonProperty()
  @FormGen({name: "Type", type: "enum", enumValues: DIRECTORY_OFFICE_LOCATION_TYPE_KTS.values})
  type: string;

  @JsonProperty()
  orgUnitId: string;

  @JsonProperty()
  @FormGen({name: "Name", type: "string"})
  name: string;

  @JsonProperty()
  @FormGen({name: "Street", type: "string"})
  street: string;

  @JsonProperty()
  @FormGen({name: "City", type: "string"})
  city: string;

  @JsonProperty()
  @FormGen({name: "State", type: "string"})
  state: string;

  @JsonProperty()
  @FormGen({name: "Zip code", type: "string"})
  zipCode: string;

  @JsonProperty()
  @FormGen({name: "Color", type: "color", description: "Select a color for your location to surface in the calendar"})
  color: string;

  static createNew(): DirectoryOfficeLocation {
    return new DirectoryOfficeLocation(md5_uuid());
  }
}

export class DirectoryOfficeLocations extends DirectoryListItemLoader<DirectoryOfficeLocation> {

  private static instance: DirectoryOfficeLocations;

  static getInstance() {
    if (!this.instance) {
      this.instance = new DirectoryOfficeLocations();
    }
    return this.instance;
  }

  protected directoryPath(): string {
    return "officeLocations";
  }

  protected deserializeItem(value: any): DirectoryOfficeLocation {
    return JSON_OBJECT.deserializeObject(value, DirectoryOfficeLocation);
  }

  protected serializeItem(item: DirectoryOfficeLocation): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: DirectoryOfficeLocation, item2: DirectoryOfficeLocation): number {
    return 0;
  }
}

export enum DirectoryGroupType {
  CUSTOM = "custom",
  SYSTEM = "system",
}

export const DIRECTORY_GROUP_TYPES_KTS = new KeyTextStrings([
  $KTS(DirectoryGroupType.CUSTOM, "Custom"),
  $KTS(DirectoryGroupType.SYSTEM, "System"),
]);

@JsonObject()
export class DirectoryGroup extends DirectoryListItem {

  @FormGen({name: "Name", type: "string"})
  @JsonProperty()
  name: string;

  @FormGen({name: "Email", type: "string"})
  @JsonProperty()
  email: string;

  @FormGen({name: "Type", type: "enum", enumValues: DIRECTORY_GROUP_TYPES_KTS.values})
  @JsonProperty()
  type: DirectoryGroupType;

  @JsonProperty()
  roleIds: string[];

  static createNew(): DirectoryGroup {
    return new DirectoryGroup(md5_uuid());
  }

  constructor(id: string, type?: DirectoryGroupType, name?: string, email?: string, roleIds?: string[]) {
    super(id);
    this.type = type;
    this.name = name;
    this.email = email;
    this.roleIds = roleIds;
  }

  // Derived from Users table.
  private userIds: string[];

  async getUserIds(): Promise<string[]> {
    if (this.userIds) {
      return this.userIds;
    }
    const userIds: string[] = [];
    const users = await DirectoryUsers.getInstance().getOrLoadListItems();
    for (const user of users) {
      const groupIds = (await user.getGroups()).map(group => group.id);
      if (groupIds.includes(this.id)) {
        userIds.push(user.id);
      }
    }
    this.userIds = userIds;
    return this.userIds;
  }

  private roles: DirectoryRole[];

  async getRoles(): Promise<DirectoryRole[]> {
    if (this.roles) {
      return this.roles;
    }
    this.roles = (await DirectoryRoles.getInstance().getOrLoadListItems()).filter(role => this.roleIds?.includes(role.id));
    return this.roles;
  }

  async saveRoleIds(roleIds: string[]) {
    this.roleIds = roleIds || [];
    await DirectoryGroups.getInstance().addChildItem(this, "roleIds", this.roleIds);
    this.roles = null;
  }
}

export class DirectoryGroups extends DirectoryListItemLoader<DirectoryGroup> {

  private static instance: DirectoryGroups;

  static getInstance() {
    if (!this.instance) {
      this.instance = new DirectoryGroups();
    }
    return this.instance;
  }

  private directoryGroupsSystem: DirectoryGroup[] = [];

  setDirectoryGroupsSystem(directoryGroupsSystem: DirectoryGroup[]) {
    this.directoryGroupsSystem = directoryGroupsSystem;
    this.invalidate();
  }

  protected directoryPath(): string {
    return "groups";
  }

  protected async onAfterItemsLoaded(items: DirectoryGroup[]): Promise<DirectoryGroup[]> {
    if (this.directoryGroupsSystem) {
      for (const group of this.directoryGroupsSystem) {
        await group.onAfterItemDeserialized();
      }
      return [...this.directoryGroupsSystem, ...items];
    }
    return super.onAfterItemsLoaded(items);
  }

  protected deserializeItem(value: any): DirectoryGroup {
    return JSON_OBJECT.deserializeObject(value, DirectoryGroup);
  }

  protected serializeItem(item: DirectoryGroup): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: DirectoryGroup, item2: DirectoryGroup): number {
    return 0;
  }
}

export enum DirectoryUserStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DELETED = "deleted",
}

export const DIRECTORY_USER_STATUS_KTS = new KeyTextStrings([
  $KTS(DirectoryUserStatus.ACTIVE, "Active"),
  $KTS(DirectoryUserStatus.INACTIVE, "Inactive"),
  $KTS(DirectoryUserStatus.DELETED, "Deleted"),
]);


@JsonObject()
export class DirectoryUser extends DirectoryListItem {

  @JsonProperty()
  memberId: string;

  @JsonProperty()
  @FormGen({name: "Status", type: "enum", enumValues: DIRECTORY_USER_STATUS_KTS.values})
  status: string;

  @JsonProperty()
  permissionIds: string[];

  @JsonProperty()
  roleIds: string[];

  @JsonProperty()
  groupIds: string[];

  private readonly roleDatas = new Map<string, any>();

  static createNew(): DirectoryUser {
    return new DirectoryUser(md5_uuid());
  }

  async onAfterItemDeserialized(): Promise<void> {
    this.member = await Members.getInstance().getOrLoadMember(this.memberId);
    for (const roleId of (this.roleIds || [])) {
      await this.loadRoleData(roleId);
    }
  }

  private permissions: DirectoryPermission[];

  async getPermissions(): Promise<DirectoryPermission[]> {
    if (this.permissions) {
      return this.permissions;
    }
    this.permissions = (await DirectoryPermissions.getInstance().getOrLoadListItems()).filter(permission => this.permissionIds?.includes(permission.id));
    return this.permissions;
  }

  async savePermissionIds(permissionIds: string[]) {
    this.permissionIds = permissionIds || [];
    await DirectoryUsers.getInstance().addChildItem(this, "permissionIds", this.permissionIds);
    this.permissions = null;
  }

  private roles: DirectoryRole[];

  async getRoles(): Promise<DirectoryRole[]> {
    if (this.roles) {
      return this.roles;
    }
    this.roles = (await DirectoryRoles.getInstance().getOrLoadListItems()).filter(role => this.roleIds?.includes(role.id));
    return this.roles;
  }

  async saveRoleIds(roleIds: string[]) {
    this.roleIds = roleIds || [];
    await DirectoryUsers.getInstance().addChildItem(this, "roleIds", this.roleIds);
    this.roles = null;
  }

  getRoleData<T extends DirectoryRoleData>(roleId: string): T {
    return this.roleDatas.get(roleId);
  }

  async getOrLoadRoleData<T extends DirectoryRoleData>(roleId: string): Promise<T> {
    let roleData: T = this.roleDatas.get(roleId);
    if (roleData) {
      return roleData;
    }
    roleData = await this.loadRoleData(roleId);
    return roleData;
  }

  async loadRoleData<T extends DirectoryRoleData>(roleId: string): Promise<T> {
    const roleData = DirectoryRoleDataFactory.INSTANCE?.createRoleData(this.id, roleId) as T;
    if (!roleData) {
      return null;
    }
    await roleData.load();
    this.roleDatas.set(roleId, roleData);
    return roleData;
  }

  async saveRoleData(roleId: string, data: DirectoryRoleData) {
    this.roleDatas.set(roleId, data);
    await data.save();
  }

  private groups: DirectoryGroup[];

  async getGroups(): Promise<DirectoryGroup[]> {
    if (this.groups) {
      return this.groups;
    }
    this.groups = (await DirectoryGroups.getInstance().getOrLoadListItems()).filter(group => this.groupIds?.includes(group.id));
    return this.groups;
  }

  async saveGroupIds(groupIds: string[]) {
    this.groupIds = groupIds || [];
    await DirectoryUsers.getInstance().addChildItem(this, "groupIds", this.groupIds);
    this.groups = null;
  }

  async hasPermission(permissionId: string): Promise<boolean> {
    if ((await this.getPermissions()).find(permission => permission.id === permissionId)) {
      return true;
    }
    for (const role of (await this.getRoles())) {
      if (await role.hasPermission(permissionId)) {
        return true;
      }
    }
    return false;
  }
}

export class DirectoryUsers extends DirectoryListItemLoader<DirectoryUser> {

  private static instance: DirectoryUsers;

  static getInstance() {
    if (!this.instance) {
      this.instance = new DirectoryUsers();
    }
    return this.instance;
  }

  protected directoryPath(): string {
    return "users";
  }

  protected deserializeItem(value: any): DirectoryUser {
    return JSON_OBJECT.deserializeObject(value, DirectoryUser);
  }

  protected serializeItem(item: DirectoryUser): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: DirectoryUser, item2: DirectoryUser): number {
    return 0;
  }
}

export enum DirectoryPermissionType {
  CUSTOM = "custom",
  SYSTEM = "system",
}

export const DIRECTORY_PERMISSION_TYPE_KTS = new KeyTextStrings([
  $KTS(DirectoryPermissionType.CUSTOM, "Custom"),
  $KTS(DirectoryPermissionType.SYSTEM, "System"),
]);

@JsonObject()
export class DirectoryPermission extends DirectoryListItem {

  @FormGen({name: "Name", type: "string"})
  @JsonProperty()
  name: string;

  @FormGen({name: "Description", type: "rich_text"})
  @JsonProperty()
  description: string;

  @FormGen({name: "Type", type: "enum", enumValues: DIRECTORY_PERMISSION_TYPE_KTS.values})
  @JsonProperty()
  type: DirectoryPermissionType;

  static createNew(): DirectoryPermission {
    return new DirectoryPermission(md5_uuid(), DirectoryPermissionType.CUSTOM);
  }

  constructor(id: string, type: DirectoryPermissionType, name?: string, description?: string) {
    super(id);
    this.type = type;
    this.name = name;
    this.description = description;
  }
}

export class DirectoryPermissions extends DirectoryListItemLoader<DirectoryPermission> {

  private static instance: DirectoryPermissions;

  static getInstance() {
    if (!this.instance) {
      this.instance = new DirectoryPermissions();
    }
    return this.instance;
  }

  private directoryPermissionsSystem: DirectoryPermission[] = [];

  setDirectoryPermissionsSystem(directoryPermissionsSystem: DirectoryPermission[]) {
    this.directoryPermissionsSystem = directoryPermissionsSystem;
    this.invalidate();
  }

  protected directoryPath(): string {
    return "permissions";
  }

  protected async onAfterItemsLoaded(items: DirectoryPermission[]): Promise<DirectoryPermission[]> {
    if (this.directoryPermissionsSystem) {
      for (const permission of this.directoryPermissionsSystem) {
        await permission.onAfterItemDeserialized();
      }
      return [...this.directoryPermissionsSystem, ...items];
    }
    return [...this.directoryPermissionsSystem, ...items];
  }

  protected deserializeItem(value: any): DirectoryPermission {
    return JSON_OBJECT.deserializeObject(value, DirectoryPermission);
  }

  protected serializeItem(item: DirectoryPermission): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: DirectoryPermission, item2: DirectoryPermission): number {
    return 0;
  }
}

export enum DirectoryRoleType {
  CUSTOM = "custom",
  SYSTEM = "system",
}

export const DIRECTORY_ROLE_TYPES_KTS = new KeyTextStrings([
  $KTS(DirectoryGroupType.CUSTOM, "Custom"),
  $KTS(DirectoryGroupType.SYSTEM, "System"),
]);

export abstract class DirectoryRoleDataFactory {

  static INSTANCE: DirectoryRoleDataFactory;

  abstract createRoleData(dirUserId: string, dirRoleId: string): DirectoryRoleData;
}

@JsonObject()
export class DirectoryRole extends DirectoryListItem {

  static DirectoryRoleDataFactory: DirectoryRoleDataFactory;

  @FormGen({name: "Name", type: "string"})
  @JsonProperty()
  name: string;

  @FormGen({name: "Description", type: "string"})
  @JsonProperty()
  description: string;

  @FormGen({name: "Type", type: "enum", enumValues: DIRECTORY_ROLE_TYPES_KTS.values})
  @JsonProperty()
  type: DirectoryRoleType;

  @FormGen({name: "Permission IDs", type: "array_string"})
  @JsonProperty()
  readonly permissionIds: string[];

  static createNew(): DirectoryRole {
    return new DirectoryRole(md5_uuid(), DirectoryRoleType.CUSTOM);
  }

  constructor(id: string, type: DirectoryRoleType, name?: string, description?: string, permissionIds?: string[]) {
    super(id);
    this.type = type;
    this.name = name;
    this.description = description;
    this.permissionIds = permissionIds;
  }

  private permissions: DirectoryPermission[];

  async getPermissions(): Promise<DirectoryPermission[]> {
    if (this.permissions) {
      return this.permissions;
    }
    this.permissions = await DirectoryPermissions.getInstance().getOrLoadListItems();
    return this.permissions;
  }

  async hasPermission(permissionId: string): Promise<boolean> {
    if ((await this.getPermissions()).find(permission => permission.id === permissionId)) {
      return true;
    }
    return false;
  }
}

export class DirectoryRoles extends DirectoryListItemLoader<DirectoryRole> {

  private static instance: DirectoryRoles;

  static getInstance() {
    if (!this.instance) {
      this.instance = new DirectoryRoles();
    }
    return this.instance;
  }

  private directoryRolesSystem: DirectoryRole[] = [];

  setDirectoryRolesSystem(directoryRolesSystem: DirectoryRole[]) {
    this.directoryRolesSystem = directoryRolesSystem;
    this.invalidate();
  }

  protected directoryPath(): string {
    return "roles";
  }

  protected async onAfterItemsLoaded(items: DirectoryRole[]): Promise<DirectoryRole[]> {
    if (this.directoryRolesSystem) {
      for (const role of this.directoryRolesSystem) {
        await role.onAfterItemDeserialized();
      }
      return [...this.directoryRolesSystem, ...items];
    }
    return [...this.directoryRolesSystem, ...items];
  }

  protected deserializeItem(value: any): DirectoryRole {
    return JSON_OBJECT.deserializeObject(value, DirectoryRole);
  }

  protected serializeItem(item: DirectoryRole): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: DirectoryRole, item2: DirectoryRole): number {
    return 0;
  }
}

@JsonObject()
export abstract class DirectoryRoleData extends DirectoryObject {

  constructor(@JsonProperty() readonly dirUserId: string, @JsonProperty() readonly roleId: string) {
    super();
  }

  createDefaultLoader(): DefaultObjectLoader<DirectoryRoleData> {
    return new DirectoryObjectLoader<DirectoryRoleData>("directory/roleDatas", this.dirUserId + "-" + this.roleId, this.getType());
  }
}

@JsonObject()
export class Provisioning extends BaseListItem {

  static readonly ACCESS_CODE_CONFIG: PasskeyConfig = {
    type: "alphanumeric_upper",
    length: PROVISIONING_CODE_MAX_LENGTH,
  };

  @FormGen({
    type: "string",
    name: "Provisioning code",
    maxLength: PROVISIONING_CODE_MAX_LENGTH,
    transformText: "uppercase",
    noneditable: false,
    onSetValue: (target, value) => (target as Provisioning).id = md5(value)
  })
  @JsonProperty()
  readonly code: string;

  @FormGen({type: "string", name: "Name"})
  @JsonProperty()
  name: string;

  @FormGen({
    type: "email",
    name: "Email",
    description: "Email address for the user that will perform the initial setup."
  })
  @JsonProperty()
  email: string;

  @FormGen({
    type: "enum",
    name: "Role",
    enumValues: [
      $KTS("production", "Production"),
      $KTS("demo", "Demo"),
    ],
  })
  @JsonProperty()
  role?: string = "production";

  @JsonProperty()
  setupComplete?: boolean;

  static createNew(): Provisioning {
    const provisioningCode = passkey(this.ACCESS_CODE_CONFIG);
    return new Provisioning(md5(provisioningCode), getMemberAuth().getMemberId(), Date.now(), provisioningCode);
  }

  constructor(id: string, creator: string, created: number, code: string) {
    super(id, creator, created);
    this.code = code;
  }
}

export class Provisionings extends BaseListItemsLoader<Provisioning> {

  private static instance;

  static getInstance(): Provisionings {
    if (!this.instance) {
      this.instance = new Provisionings();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "provisionings";
  }

  protected deserializeItem(value: any): Provisioning {
    return JSON_OBJECT.deserializeObject(value, Provisioning);
  }

  protected serializeItem(item: Provisioning): any {
    return JSON_OBJECT.serializeObject(item);
  }

  async addListItem(item: Provisioning, rel?: Rel): Promise<void> {
    await super.addListItem(item, rel);
    const rootOrgUnit = new DirectoryOrgUnitRoot(null, Date.now(), new ProvisioningContext(item.id));
    rootOrgUnit.name = item.name;
    await rootOrgUnit.save();
  }

  protected sortOrder(item1: Provisioning, item2: Provisioning): number {
    return item2.created - item1.created;
  }
}