import {ReactElement} from "react";
import {Api} from "./api";
import {BaseWidget} from "../BaseWidget";
import {StyledBoxColumn} from "../../../shared/StyledComponents";
import {Typography} from "@mui/material";
import {Clock, ClockGranularity, ClockListener} from "../../../shared/clock";
import {PD_XLG} from "../../../shared/dimens";

export type JokesContent = {
  text: string,
}

export class JokesWidget extends BaseWidget<{}, JokesContent> implements ClockListener {

  private readonly api = Api.getInstance();
  private readonly clock = Clock.getInstance();

  componentDidMount() {
    super.componentDidMount();
    this.clock.addListener(ClockGranularity.MINUTE, this);
  }

  componentWillUnmount() {
    this.clock.removeListener(ClockGranularity.MINUTE, this);
    super.componentWillUnmount();
  }

  tick(value: number) {
    this.reload();
  }

  protected async onLoadContent(): Promise<JokesContent> {
    const joke = await this.api.joke();
    return {
      text: joke.joke,
    };
  }

  protected renderContent(content: JokesContent): ReactElement {
    return <StyledBoxColumn>
      <img src="/images/widgets/jokes/jokes.jpg" style={{width: "100%", aspectRatio: 1, marginBottom: -16}}/>
      <Typography style={{padding: PD_XLG, fontWeight: "bold", fontSize: "125%"}}>{content.text}</Typography>
    </StyledBoxColumn>;
  }
}
