import {JsonProperty} from './json/json-property';
import {JsonObject} from './json/json-object';

export enum Environment {
  DEBUG = "debug",
  EMULATOR = "emulator",
  PROD = "prod",
}

export type BaseRequest = {
  memberId: string,
}

export type ResponseStatus = {
  code: number,
  text: string,
}

export type BaseResponse = {
  status: ResponseStatus,
}

export const OK_STATUS: ResponseStatus = {
  code: 200,
  text: "Ok",
}

export const ERROR_STATUS: ResponseStatus = {
  code: 400,
  text: "Invalid request",
}

export const NOT_FOUND_STATUS: ResponseStatus = {
  code: 404,
  text: "Not found",
}

export function $PROP<T>(name: keyof T) {
  return name;
}

@JsonObject()
export abstract class BaseListItem {

  @JsonProperty()
  id: string;

  @JsonProperty()
  creator: string;

  @JsonProperty()
  created: number;

  protected constructor(id: string, creator?: string, created?: number) {
    this.id = id;
    this.creator = creator;
    this.created = created;
  }

  async onAfterItemDeserialized(): Promise<void> {
  }

  async onBeforeItemSerialized(): Promise<void> {
  }
}
