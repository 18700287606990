export function FormatTimeDiffInSeconds(time: number): string {
  time = Math.floor(time);
  const hours = time >= 3600 ? Math.floor(time / 3600).toString() : undefined;
  const minutes = Math.floor(time / 60).toString();
  const seconds = (time % 60).toString();
  let formatted = minutes.padStart(2, "0") + ":" + seconds.padStart(2, "0");
  if (hours) {
    formatted = hours.padStart(2, "0") + ":" + formatted;
  }
  return formatted;
}

export enum ClockGranularity {
  SECOND,
  MINUTE,
  HOUR,
}

export interface ClockListener {
  tick(value: number);
}

export class Clock {

  private static instance = new Clock();

  static getInstance(): Clock {
    return this.instance;
  }

  private readonly listenersMap = new Map<ClockGranularity, ClockListener[]>();

  private started: boolean;

  private second: number;
  private minute: number;
  private hour: number;

  private tick() {
    const date = new Date();
    const second = date.getSeconds();
    const minute = date.getMinutes();
    const hour = date.getHours();
    if (this.second !== second) {
      this.second = second;
      this.notifyListeners(ClockGranularity.SECOND, second);
    }
    if (this.minute !== minute) {
      this.minute = minute;
      this.notifyListeners(ClockGranularity.MINUTE, minute);
    }
    if (this.hour !== hour) {
      this.hour = hour;
      this.notifyListeners(ClockGranularity.HOUR, hour);
    }
    const diff = 1000 - (Date.now() + 1000) % 1000;
    setTimeout(() => this.tick(), diff);
  }

  addListener(granularity: ClockGranularity, listener: ClockListener) {
    let listeners = this.listenersMap.get(granularity);
    if (!listeners) {
      listeners = [];
      this.listenersMap.set(granularity, listeners);
    }
    listeners.push(listener);
    if (!this.started) {
      this.started = true;
      this.tick();
    }
  }

  removeListener(granularity: ClockGranularity, listener: ClockListener) {
    let listeners = this.listenersMap.get(granularity);
    if (listeners) {
      const index = listeners.indexOf(listener);
      if (index >= 0) {
        listeners.splice(index, 1);
      }
    }
  }

  private notifyListeners(granularity: ClockGranularity, value: number) {
    const listeners = this.listenersMap.get(granularity);
    if (listeners) {
      listeners.forEach(listener => listener.tick(value));
    }
  }
}