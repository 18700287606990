import React from "react";
import {Box, Typography} from "@mui/material";
import {PD_SM} from "./dimens";

export type PopoverContainerProps = {
  title: string,
  children?: any,
}

export function PopoverContainer(props: PopoverContainerProps) {

  return <Box style={{display: "flex", flexDirection: "column"}}>
    <Box style={{display: "flex", flexDirection: "column", padding: PD_SM}}>
      <Typography variant="h6">{props.title}</Typography>
    </Box>
    {props.children}
  </Box>;
}
