import {BASE_STATE, BaseFragment, BaseFragmentProps, BaseFragmentState} from "shared/BaseFragment";
import {Box, Button, Card, Fade, IconButton, Pagination, Slide, Typography} from "@mui/material";
import {BORDER_RADIUS_LARGE, PD_MD, PD_XLG, PD_XSM, SZ_SM} from "shared/dimens";
import {ArrowBackIosRounded, ArrowForwardIosRounded} from "@mui/icons-material";
import React, {ReactNode} from "react";
import {lightGray} from "shared/colors";
import {THEME} from "../../fyneapps-shared/FyneappsApp";
import {DRAWER_ITEMS, DrawerItem, DrawerItemType} from "../../fyneapps-shared/types";
import {findIcon} from "../../shared/icons";

const ITEMS = DRAWER_ITEMS.filter(item => item.type === DrawerItemType.LINK);
const ITEMS_PER_PAGE = 8;
const ITEMS_PER_ROW = 4;
const NUM_PAGES = Math.ceil(ITEMS.length / ITEMS_PER_PAGE);
const NUM_ROWS = ITEMS_PER_PAGE / ITEMS_PER_ROW;

export type AppsFragmentProps = BaseFragmentProps & {
  listener: AppsListener,
}

type AppsFragmentState = BaseFragmentState & {
  pageIndex: number,
}

export interface AppsListener {
  onHide();
}

export class AppsFragment extends BaseFragment<AppsFragmentProps, AppsFragmentState> {

  protected onCreateState(): AppsFragmentState {
    return {
      ...BASE_STATE,
      pageIndex: 0,
    };
  }

  protected renderContainerContent(): React.ReactElement | null {
    let prevDisabled = this.state.pageIndex === 0;
    let nextDisabled = this.state.pageIndex === NUM_PAGES - 1;
    return <Slide direction="up" in mountOnEnter unmountOnExit>
      <Box style={{display: "flex", justifyContent: "center", alignItems: "center", flexGrow: 1}}
           onClick={() => this.props.listener?.onHide()}>
        <Box style={{display: "flex", flexDirection: "column", gap: PD_XLG, alignItems: "center"}}
             onClick={event => event.stopPropagation()}>
          <Box style={{display: "flex", alignItems: "center", gap: PD_XLG}}>
            <IconButton style={{
              width: SZ_SM,
              height: SZ_SM,
              background: lightGray,
              opacity: prevDisabled ? 0 : 1,
            }} disabled={prevDisabled} onClick={() => {
              this.setPageIndex(this.state.pageIndex - 1);
            }}>
              <ArrowBackIosRounded style={{color: "black"}}/>
            </IconButton>
            <Box style={{
              display: "flex",
              flexDirection: "column",
              // paddingLeft: PD_XLG,
              // paddingRight: PD_XLG,
              // paddingTop: PD_LG,
              // paddingBottom: PD_LG,
              width: 720,
              height: 400,
              padding: 48,
              borderRadius: BORDER_RADIUS_LARGE,
              background: "rgba(255, 255, 255, 0.1)",
            }}>
              {this.renderPage(this.state.pageIndex)}
            </Box>
            <IconButton style={{
              width: SZ_SM,
              height: SZ_SM,
              background: lightGray,
              opacity: nextDisabled ? 0 : 1,
            }} disabled={nextDisabled} onClick={() => {
              this.setPageIndex(this.state.pageIndex + 1);
            }}>
              <ArrowForwardIosRounded style={{color: "black"}}/>
            </IconButton>
          </Box>
          <div style={{
            padding: 8,
            borderRadius: BORDER_RADIUS_LARGE,
            background: "rgba(255, 255, 255, 0.9)",
          }}>
            <Pagination
              count={NUM_PAGES}
              page={this.state.pageIndex + 1}
              style={{margin: "auto"}}
              onChange={(event, page) => this.setPageIndex(page - 1)}/>
          </div>
        </Box>
      </Box>
    </Slide>;
  }

  private setPageIndex(pageIndex: number) {
    this.setState({pageIndex: pageIndex});
  }

  private renderPage(pageIndex: number): ReactNode {
    const pageItems = ITEMS.slice(pageIndex * ITEMS_PER_PAGE, (pageIndex + 1) * ITEMS_PER_PAGE)
    return Array(NUM_ROWS).fill(0).map((_, index) => <Box
      style={{display: "flex", flexDirection: "column", gap: PD_XLG}}>
      <Box className="hidescroll" style={{
        display: "flex",
        margin: "auto",
        paddingLeft: 48,
        paddingRight: 48,
        gap: PD_XLG,
        flexDirection: "row",
        overflowX: "scroll",
      }}>
        {pageItems.slice(index * ITEMS_PER_ROW, (index + 1) * ITEMS_PER_ROW).map(item => this.renderItem(item))}
      </Box>
    </Box>);
  }

  // protected renderContainerContent(): React.ReactElement | null {
  //   return <Box
  //     style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
  //     <Box style={{display: "flex", flexDirection: "column", gap: PD_XLG, alignItems: "center"}}>
  //       <img src="/stamp.png" style={{width: 192, borderRadius: BORDER_RADIUS}}/>
  //       <Typography>Please choose an app</Typography>
  //       <StyledBoxColumn>
  //         <Box style={{display: "flex", gap: PD_XLG}}>
  //           {ITEMS.map(item => this.renderItem(item))}
  //         </Box>
  //       </StyledBoxColumn>
  //     </Box>
  //   </Box>;
  // }

  private renderItem(item: DrawerItem) {
    const IconType = findIcon(item.iconUrl.substring("@icon/".length));
    return <Button
      style={{
        flexShrink: 0,
        paddingLeft: PD_XSM,
        paddingRight: PD_XSM,
      }}
      onClick={() => window.open(item.url, "_blank")}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: 120,
          gap: PD_MD,
        }}>
        <Card style={{
          width: "100%",
          aspectRatio: 1,
          borderRadius: "16%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: THEME.palette.primary.main,
          color: THEME.palette.primary.contrastText,
        }}>
          <IconType style={{width: "50%", height: "50%"}}/>
        </Card>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography style={{
            color: "white",
            paddingTop: PD_XSM,
            paddingBottom: PD_XSM,
            paddingLeft: PD_MD,
            paddingRight: PD_MD,
            fontWeight: "bold",
          }}>
            {item.text}
          </Typography>
        </Box>
      </Box>
    </Button>;
  }
}
