import React, {ReactElement} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Location,
  Navigate,
  NavigateFunction,
  Route,
  RouterProvider,
  useLocation,
  useMatches,
  useNavigate,
  useParams
} from "react-router-dom";
import {App} from 'app/App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export type PathComponent = {
  path: string,
  handle?: any,
  children?: PathComponent[],
  render: (pathProps: PathProps) => ReactElement,
}

export type PathProps = {
  location: Location,
  navigate: NavigateFunction,
  matches: any[],
  params: any,
}

function Element(props: { render: (PathProps) => React.ReactElement }) {
  const pathProps: PathProps = {
    location: useLocation(),
    navigate: useNavigate(),
    matches: useMatches(),
    params: useParams(),
  };
  return props.render(pathProps);
}

function createRoutes(path: PathComponent): ReactElement {
  return <Route
    path={path.path}
    handle={path.handle}
    element={<Element render={path.render}/>}
  >
    {path.children?.map(nestedPath => createRoutes(nestedPath))}
    {path.children?.length > 0
      ? <Route
        index
        element={<Navigate to={path.children[0].path}/>}
      />
      : null
    }
  </Route>;
}

const nestedPaths = App.nestedPaths?.();

root.render(
  <React.StrictMode>
    <RouterProvider router={createBrowserRouter(createRoutesFromElements(createRoutes({
      path: "/",
      children: nestedPaths,
      render: (pathProps: PathProps) => <App path={pathProps}/>,
    })))}/>
  </React.StrictMode>
);
