import {DateUtil, FORMAT_ABBREV_MONTH, FORMAT_NO_YEAR, FORMAT_SHOW_DATE, FORMAT_SHOW_YEAR} from "./date_util";

export const SECONDS = 0;
export const MINUTES = 1;
export const HOUR = 2;
export const HOURS = 3;
export const TODAY = 4;
export const TOMORROW = 5;
export const YESTERDAY = 6;
export const WEEK = 7;
export const YEAR = 8;
export const ANY = 9;

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export class DisplayTime {
  constructor(readonly text: string, readonly unit: number) {
  }
}

export abstract class TransformSpec {
  constructor(readonly timeDiffUpperBoundSeconds: number) {
  }

  abstract transform(timeDiff: number, text: string): string;
}

export class TimeDiffSpec {
  static readonly TRUE = new TimeDiffSpec(true, []);
  static readonly FALSE = new TimeDiffSpec(false, []);


  constructor(readonly invert: boolean, readonly transformSpecs: TransformSpec[]) {
    this.transformSpecs.sort((spec1, spec2) => spec1.timeDiffUpperBoundSeconds - spec2.timeDiffUpperBoundSeconds);
  }

  transform(timeDiff: number, text: string): string {
    for (let spec of this.transformSpecs) {
      if (timeDiff < spec.timeDiffUpperBoundSeconds) {
        return spec.transform(timeDiff, text);
      }
    }
    return text;
  }
}

export class Text {

  private static ONE_MINUTE_IN_SECONDS = 60;
  private static ONE_HOUR_IN_SECONDS = Text.ONE_MINUTE_IN_SECONDS * 60;
  private static ONE_DAY_IN_SECONDS = Text.ONE_HOUR_IN_SECONDS * 24;
  private static ONE_WEEK_IN_SECONDS = Text.ONE_DAY_IN_SECONDS * 7;
  private static ONE_YEAR_IN_SECONDS = Text.ONE_DAY_IN_SECONDS * 365;

  private static MONTH_DAY =
    FORMAT_SHOW_DATE | FORMAT_NO_YEAR | FORMAT_ABBREV_MONTH;
  private static MONTH_DAY_YEAR =
    FORMAT_SHOW_DATE | FORMAT_SHOW_YEAR | FORMAT_ABBREV_MONTH;

  static getTimestampText(timestamp: number) {
    // count the elapsed time between the given time and now
    return Text.getTimeDiff(timestamp, TimeDiffSpec.FALSE);
  }

  static getDurationText(duration: number): DisplayTime {
    let remainingSeconds = Math.floor(duration / 1000);
    let text;
    let unit;
    if (remainingSeconds < Text.ONE_MINUTE_IN_SECONDS) {
      text = "Just now";
      unit = SECONDS;
    } else if (remainingSeconds < Text.ONE_MINUTE_IN_SECONDS * 2) {
      text = "1 minute";
      unit = SECONDS;
    } else if (remainingSeconds < Text.ONE_HOUR_IN_SECONDS) {
      let remainingMin = Math.floor(remainingSeconds / 60);
      text = remainingMin + " minutes";
      unit = MINUTES;
    } else if (remainingSeconds < Text.ONE_HOUR_IN_SECONDS * 2) {
      text = "1 hour";
      unit = HOUR;
    } else {
      let remainingHrs = Math.floor(remainingSeconds / (60 * 60));
      text = remainingHrs + " hours";
      unit = HOURS;
    }
    return new DisplayTime(text, unit);
  }

  static getRemainingTimeText(timestamp: number, ...specs: TransformSpec[]): DisplayTime {
    // count the remaining time between the given time and now
    return Text.getTimeDiff(timestamp, specs === null || specs.length === 0 ? TimeDiffSpec.TRUE : new TimeDiffSpec(true, specs));
  }

  static getTimeDiff(timestamp: number, spec: TimeDiffSpec): DisplayTime {
    let givenDate = new Date(timestamp);
    let nowDate = new Date();
    let timeDiff = (spec.invert ? -1 : 1) * (nowDate.getTime() - timestamp) / 1000;
    let text: string;
    let unit: number;
    if (timeDiff >= 0) {
      if (timeDiff < Text.ONE_MINUTE_IN_SECONDS) {
        text = spec.transform(timeDiff, "Just now");
        unit = SECONDS;
      } else if (timeDiff < Text.ONE_MINUTE_IN_SECONDS * 2) {
        text = spec.transform(timeDiff, "1 minute");
        unit = MINUTES;
      } else if (timeDiff < Text.ONE_HOUR_IN_SECONDS) {
        let remainingMin = Math.floor(timeDiff / 60);
        text = spec.transform(timeDiff, remainingMin + " minutes");
        unit = MINUTES;
      } else if (timeDiff < Text.ONE_HOUR_IN_SECONDS * 2) {
        text = spec.transform(timeDiff, "1 hour");
        unit = HOUR;
      } else if (timeDiff < Text.ONE_HOUR_IN_SECONDS * 10) {
        let remainingHrs = Math.floor(timeDiff / (60 * 60));
        text = spec.transform(timeDiff, remainingHrs + " hours");
        unit = HOURS;
      } else if (nowDate.getDay() === givenDate.getDay() && timeDiff < Text.ONE_DAY_IN_SECONDS) {
        text = spec.transform(timeDiff, "Today");
        unit = TODAY;
      } else if (Text.getTomorrow(nowDate) === givenDate.getDay()
        && timeDiff < Text.ONE_DAY_IN_SECONDS * 2) {
        text = spec.transform(timeDiff, "Tomorrow");
        unit = TOMORROW;
      } else if (timeDiff < Text.ONE_WEEK_IN_SECONDS) {
        text = spec.transform(timeDiff, Text.getDayOfWeekStringForDate(givenDate));
        unit = WEEK;
      } else if (timeDiff < Text.ONE_YEAR_IN_SECONDS) {
        text = spec.transform(timeDiff, Text.getDateString(timestamp));
        unit = YEAR;
      } else {
        text = spec.transform(timeDiff, DateUtil.formatDateTime(timestamp, Text.MONTH_DAY_YEAR));
        unit = ANY;
      }
    } else {
      if (Text.getYesterday(nowDate) === givenDate.getDay()) {
        text = spec.transform(timeDiff, "Yesterday");
        unit = YESTERDAY;
      } else {
        timeDiff -= timeDiff;
        if (timeDiff < Text.ONE_WEEK_IN_SECONDS) {
          text = spec.transform(timeDiff, Text.getDayOfWeekStringForDate(givenDate));
          unit = WEEK;
        } else if (timeDiff < Text.ONE_YEAR_IN_SECONDS) {
          text = spec.transform(timeDiff, Text.getDateString(timestamp));
          unit = YEAR;
        } else {
          text = spec.transform(timeDiff, DateUtil.formatDateTime(timestamp, Text.MONTH_DAY_YEAR));
          unit = ANY;
        }
      }
    }

    return new DisplayTime(text, unit);
  }

  static getDayOfWeekStringForDate(date: Date): string {
    return new Intl.DateTimeFormat('default', {weekday: "long"}).format(date);
  }

  static getTomorrow(date: Date): number {
    let tomorrow = date.getDay() + 1;
    return tomorrow === 7 ? 0 : tomorrow;
  }

  static getYesterday(date: Date): number {
    let yesterday = date.getDay() - 1;
    return yesterday === -1 ? 6 : yesterday;
  }

  static getDateString(millisSinceEpoch: number): string {
    return DateUtil.formatDateTime(millisSinceEpoch, Text.MONTH_DAY);
  }
}

