import {Auth, getAuth} from "@firebase/auth";
import {Member, Members, MembersKey} from "./entities";
import {BaseApp} from "./BaseApp";
import {AppletContext, PluginContext} from "./types";

export class MemberAuth {
  constructor(readonly member: Member, readonly auth: Auth) {
  }

  getMemberId(): string {
    return this.member?.memberId;
  }
}

const MEMBER_AUTH = new Map<string, MemberAuth>();

function inferKey(key: MembersKey) {
  if (!key) {
    let contextType = BaseApp.CONTEXT.contextType();
    if (contextType === "plugin") {
      const pluginContext = BaseApp.CONTEXT as PluginContext;
      key = pluginContext.getPluginConfig()?.membersKey;
    } else if (contextType === "applet") {
      const appletContext = BaseApp.CONTEXT as AppletContext;
      key = appletContext.getAppletConfig()?.membersKey;
    }
  }
  if (!key) {
    key = MembersKey.DEFAULT;
  }
  return key;
}

export async function initMemberAuth(key?: MembersKey): Promise<MemberAuth> {
  key = inferKey(key);
  const path = key.path();
  let memberAuth = MEMBER_AUTH.get(path);
  if (!memberAuth) {
    const auth = getAuth();
    const member = await Members.getInstance(key).getOrLoadMemberByUid(auth.currentUser.uid, true);
    if (member) {
      member.key = key;
      memberAuth = new MemberAuth(member, auth);
      MEMBER_AUTH.set(path, memberAuth);
    }
  }
  return memberAuth;
}

export function getMemberAuth(key?: MembersKey): MemberAuth {
  key = inferKey(key);
  const path = key.path();
  return MEMBER_AUTH.get(path);
}
