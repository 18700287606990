import {Environment} from "../../common/types";

export const ENV: Environment = Environment.PROD;

export function getCloudHost() {
  switch (ENV) {
    case Environment.DEBUG:
      return "http://localhost:3002";
    case Environment.EMULATOR:
      return "http://127.0.0.1:5001/fynepix-5da86/us-central1/c0";
    default:
    case Environment.PROD:
      return "https://us-central1-fynepix-5da86.cloudfunctions.net/c0";
  }
}
