/**
 * Two-way map that maps MIME-types to file extensions and vice versa.
 */
import {Folder, Image, InsertDriveFile, LinkOutlined, LiveHelp, Movie, MusicNote, Title} from "@mui/icons-material";
import {black, colorBlue, colorDarkPurple, colorLightGreen, colorOrange, colorRed, mediumGray} from "./colors";
import {SvgIcon} from "@mui/material";

export class MimeTypeMap {
  static readonly MimeType_application_andrew_inset = "application/andrew-inset";
  static readonly MimeType_application_dsptype = "application/dsptype";
  static readonly MimeType_application_futuresplash = "application/futuresplash";
  static readonly MimeType_application_hta = "application/hta";
  static readonly MimeType_application_json = "application/json";
  static readonly MimeType_application_mac_binhex40 = "application/mac-binhex40";
  static readonly MimeType_application_mac_compactpro = "application/mac-compactpro";
  static readonly MimeType_application_mathematica = "application/mathematica";
  static readonly MimeType_application_msaccess = "application/msaccess";
  static readonly MimeType_application_oda = "application/oda";
  static readonly MimeType_application_ogg = "application/ogg";
  static readonly MimeType_application_pdf = "application/pdf";
  static readonly MimeType_application_pgp_keys = "application/pgp-keys";
  static readonly MimeType_application_pgp_signature = "application/pgp-signature";
  static readonly MimeType_application_pics_rules = "application/pics-rules";
  static readonly MimeType_application_rar = "application/rar";
  static readonly MimeType_application_rdf_xml = "application/rdf+xml";
  static readonly MimeType_application_rss_xml = "application/rss+xml";
  static readonly MimeType_application_zip = "application/zip";
  static readonly MimeType_application_vnd_android_package_archive = "application/vnd.android.package-archive";
  static readonly MimeType_application_vnd_cinderella = "application/vnd.cinderella";
  static readonly MimeType_application_vnd_ms_pki_stl = "application/vnd.ms-pki.stl";
  static readonly MimeType_application_vnd_oasis_opendocument_database = "application/vnd.oasis.opendocument.database";
  static readonly MimeType_application_vnd_oasis_opendocument_formula = "application/vnd.oasis.opendocument.formula";
  static readonly MimeType_application_vnd_oasis_opendocument_graphics = "application/vnd.oasis.opendocument.graphics";
  static readonly MimeType_application_vnd_oasis_opendocument_graphics_template = "application/vnd.oasis.opendocument.graphics-template";
  static readonly MimeType_application_vnd_oasis_opendocument_image = "application/vnd.oasis.opendocument.image";
  static readonly MimeType_application_vnd_oasis_opendocument_spreadsheet = "application/vnd.oasis.opendocument.spreadsheet";
  static readonly MimeType_application_vnd_oasis_opendocument_spreadsheet_template = "application/vnd.oasis.opendocument.spreadsheet-template";
  static readonly MimeType_application_vnd_oasis_opendocument_text = "application/vnd.oasis.opendocument.text";
  static readonly MimeType_application_vnd_oasis_opendocument_text_master = "application/vnd.oasis.opendocument.text-master";
  static readonly MimeType_application_vnd_oasis_opendocument_text_template = "application/vnd.oasis.opendocument.text-template";
  static readonly MimeType_application_vnd_oasis_opendocument_text_web = "application/vnd.oasis.opendocument.text-web";
  static readonly MimeType_application_msword = "application/msword";
  static readonly MimeType_application_vnd_openxmlformats_officedocument_wordprocessingml_document = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  static readonly MimeType_application_vnd_openxmlformats_officedocument_wordprocessingml_template = "application/vnd.openxmlformats-officedocument.wordprocessingml.template";
  static readonly MimeType_application_vnd_ms_excel = "application/vnd.ms-excel";
  static readonly MimeType_application_vnd_openxmlformats_officedocument_spreadsheetml_sheet = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  static readonly MimeType_application_vnd_openxmlformats_officedocument_spreadsheetml_template = "application/vnd.openxmlformats-officedocument.spreadsheetml.template";
  static readonly MimeType_application_vnd_ms_powerpoint = "application/vnd.ms-powerpoint";
  static readonly MimeType_application_vnd_openxmlformats_officedocument_presentationml_presentation = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  static readonly MimeType_application_vnd_openxmlformats_officedocument_presentationml_template = "application/vnd.openxmlformats-officedocument.presentationml.template";
  static readonly MimeType_application_vnd_openxmlformats_officedocument_presentationml_slideshow = "application/vnd.openxmlformats-officedocument.presentationml.slideshow";
  static readonly MimeType_application_vnd_rim_cod = "application/vnd.rim.cod";
  static readonly MimeType_application_vnd_smaf = "application/vnd.smaf";
  static readonly MimeType_application_vnd_stardivision_calc = "application/vnd.stardivision.calc";
  static readonly MimeType_application_vnd_stardivision_draw = "application/vnd.stardivision.draw";
  static readonly MimeType_application_vnd_stardivision_impress = "application/vnd.stardivision.impress";
  static readonly MimeType_application_vnd_stardivision_math = "application/vnd.stardivision.math";
  static readonly MimeType_application_vnd_stardivision_writer = "application/vnd.stardivision.writer";
  static readonly MimeType_application_vnd_stardivision_writer_global = "application/vnd.stardivision.writer-global";
  static readonly MimeType_application_vnd_sun_xml_calc = "application/vnd.sun.xml.calc";
  static readonly MimeType_application_vnd_sun_xml_calc_template = "application/vnd.sun.xml.calc.template";
  static readonly MimeType_application_vnd_sun_xml_draw = "application/vnd.sun.xml.draw";
  static readonly MimeType_application_vnd_sun_xml_draw_template = "application/vnd.sun.xml.draw.template";
  static readonly MimeType_application_vnd_sun_xml_impress = "application/vnd.sun.xml.impress";
  static readonly MimeType_application_vnd_sun_xml_impress_template = "application/vnd.sun.xml.impress.template";
  static readonly MimeType_application_vnd_sun_xml_math = "application/vnd.sun.xml.math";
  static readonly MimeType_application_vnd_sun_xml_writer = "application/vnd.sun.xml.writer";
  static readonly MimeType_application_vnd_sun_xml_writer_global = "application/vnd.sun.xml.writer.global";
  static readonly MimeType_application_vnd_sun_xml_writer_template = "application/vnd.sun.xml.writer.template";
  static readonly MimeType_application_vnd_visio = "application/vnd.visio";
  static readonly MimeType_application_x_abiword = "application/x-abiword";
  static readonly MimeType_application_x_apple_diskimage = "application/x-apple-diskimage";
  static readonly MimeType_application_x_bcpio = "application/x-bcpio";
  static readonly MimeType_application_x_bittorrent = "application/x-bittorrent";
  static readonly MimeType_application_x_cdf = "application/x-cdf";
  static readonly MimeType_application_x_cdlink = "application/x-cdlink";
  static readonly MimeType_application_x_chess_pgn = "application/x-chess-pgn";
  static readonly MimeType_application_x_cpio = "application/x-cpio";
  static readonly MimeType_application_x_debian_package = "application/x-debian-package";
  static readonly MimeType_application_x_director = "application/x-director";
  static readonly MimeType_application_x_dms = "application/x-dms";
  static readonly MimeType_application_x_doom = "application/x-doom";
  static readonly MimeType_application_x_dvi = "application/x-dvi";
  static readonly MimeType_application_x_flac = "application/x-flac";
  static readonly MimeType_application_x_font = "application/x-font";
  static readonly MimeType_application_x_freemind = "application/x-freemind";
  static readonly MimeType_application_x_futuresplash = "application/x-futuresplash";
  static readonly MimeType_application_x_gnumeric = "application/x-gnumeric";
  static readonly MimeType_application_x_go_sgf = "application/x-go-sgf";
  static readonly MimeType_application_x_graphing_calculator = "application/x-graphing-calculator";
  static readonly MimeType_application_x_gtar = "application/x-gtar";
  static readonly MimeType_application_x_hdf = "application/x-hdf";
  static readonly MimeType_application_x_ica = "application/x-ica";
  static readonly MimeType_application_x_internet_signup = "application/x-internet-signup";
  static readonly MimeType_application_x_iphone = "application/x-iphone";
  static readonly MimeType_application_x_iso9660_image = "application/x-iso9660-image";
  static readonly MimeType_application_x_jmol = "application/x-jmol";
  static readonly MimeType_application_x_kchart = "application/x-kchart";
  static readonly MimeType_application_x_killustrator = "application/x-killustrator";
  static readonly MimeType_application_x_koan = "application/x-koan";
  static readonly MimeType_application_x_kpresenter = "application/x-kpresenter";
  static readonly MimeType_application_x_kspread = "application/x-kspread";
  static readonly MimeType_application_x_kword = "application/x-kword";
  static readonly MimeType_application_x_latex = "application/x-latex";
  static readonly MimeType_application_x_lha = "application/x-lha";
  static readonly MimeType_application_x_lzh = "application/x-lzh";
  static readonly MimeType_application_x_lzx = "application/x-lzx";
  static readonly MimeType_application_x_maker = "application/x-maker";
  static readonly MimeType_application_x_mif = "application/x-mif";
  static readonly MimeType_application_x_ms_wmd = "application/x-ms-wmd";
  static readonly MimeType_application_x_ms_wmz = "application/x-ms-wmz";
  static readonly MimeType_application_x_msi = "application/x-msi";
  static readonly MimeType_application_x_ns_proxy_autoconfig = "application/x-ns-proxy-autoconfig";
  static readonly MimeType_application_x_nwc = "application/x-nwc";
  static readonly MimeType_application_x_object = "application/x-object";
  static readonly MimeType_application_x_oz_application = "application/x-oz-application";
  static readonly MimeType_application_x_pkcs12 = "application/x-pkcs12";
  static readonly MimeType_application_x_pkcs7_certreqresp = "application/x-pkcs7-certreqresp";
  static readonly MimeType_application_x_pkcs7_crl = "application/x-pkcs7-crl";
  static readonly MimeType_application_x_quicktimeplayer = "application/x-quicktimeplayer";
  static readonly MimeType_application_x_shar = "application/x-shar";
  static readonly MimeType_application_x_shockwave_flash = "application/x-shockwave-flash";
  static readonly MimeType_application_x_stuffit = "application/x-stuffit";
  static readonly MimeType_application_x_sv4cpio = "application/x-sv4cpio";
  static readonly MimeType_application_x_sv4crc = "application/x-sv4crc";
  static readonly MimeType_application_x_tar = "application/x-tar";
  static readonly MimeType_application_x_texinfo = "application/x-texinfo";
  static readonly MimeType_application_x_troff = "application/x-troff";
  static readonly MimeType_application_x_troff_man = "application/x-troff-man";
  static readonly MimeType_application_x_ustar = "application/x-ustar";
  static readonly MimeType_application_x_wais_source = "application/x-wais-source";
  static readonly MimeType_application_x_wingz = "application/x-wingz";
  static readonly MimeType_application_x_webarchive = "application/x-webarchive";
  static readonly MimeType_application_x_x509_ca_cert = "application/x-x509-ca-cert";
  static readonly MimeType_application_x_xcf = "application/x-xcf";
  static readonly MimeType_application_x_xfig = "application/x-xfig";
  static readonly MimeType_application_xhtml_xml = "application/xhtml+xml";
  static readonly MimeType_audio_aac = "audio/mp4a-latm";
  static readonly MimeType_audio_3gpp = "audio/3gpp";
  static readonly MimeType_audio_basic = "audio/basic";
  static readonly MimeType_audio_midi = "audio/midi";
  static readonly MimeType_audio_mpeg = "audio/mpeg";
  static readonly MimeType_audio_mpegurl = "audio/mpegurl";
  static readonly MimeType_audio_prs_sid = "audio/prs.sid";
  static readonly MimeType_audio_x_aiff = "audio/x-aiff";
  static readonly MimeType_audio_x_gsm = "audio/x-gsm";
  static readonly MimeType_audio_x_mpegurl = "audio/x-mpegurl";
  static readonly MimeType_audio_x_ms_wma = "audio/x-ms-wma";
  static readonly MimeType_audio_x_ms_wax = "audio/x-ms-wax";
  static readonly MimeType_audio_x_pn_realaudio = "audio/x-pn-realaudio";
  static readonly MimeType_audio_x_realaudio = "audio/x-realaudio";
  static readonly MimeType_audio_x_scpls = "audio/x-scpls";
  static readonly MimeType_audio_x_sd2 = "audio/x-sd2";
  static readonly MimeType_audio_x_wav = "audio/x-wav";
  static readonly MimeType_image_bmp = "image/bmp";
  static readonly MimeType_image_gif = "image/gif";
  static readonly MimeType_image_ico = "image/ico";
  static readonly MimeType_image_ief = "image/ief";
  static readonly MimeType_image_jpeg = "image/jpeg";
  static readonly MimeType_image_pcx = "image/pcx";
  static readonly MimeType_image_png = "image/png";
  static readonly MimeType_image_svg_xml = "image/svg+xml";
  static readonly MimeType_image_tiff = "image/tiff";
  static readonly MimeType_image_vnd_djvu = "image/vnd.djvu";
  static readonly MimeType_image_vnd_wap_wbmp = "image/vnd.wap.wbmp";
  static readonly MimeType_image_webp = "image/webp";
  static readonly MimeType_image_x_cmu_raster = "image/x-cmu-raster";
  static readonly MimeType_image_x_coreldraw = "image/x-coreldraw";
  static readonly MimeType_image_x_coreldrawpattern = "image/x-coreldrawpattern";
  static readonly MimeType_image_x_coreldrawtemplate = "image/x-coreldrawtemplate";
  static readonly MimeType_image_x_corelphotopaint = "image/x-corelphotopaint";
  static readonly MimeType_image_x_icon = "image/x-icon";
  static readonly MimeType_image_x_jg = "image/x-jg";
  static readonly MimeType_image_x_jng = "image/x-jng";
  static readonly MimeType_image_x_ms_bmp = "image/x-ms-bmp";
  static readonly MimeType_image_x_photoshop = "image/x-photoshop";
  static readonly MimeType_image_x_portable_anymap = "image/x-portable-anymap";
  static readonly MimeType_image_x_portable_bitmap = "image/x-portable-bitmap";
  static readonly MimeType_image_x_portable_graymap = "image/x-portable-graymap";
  static readonly MimeType_image_x_portable_pixmap = "image/x-portable-pixmap";
  static readonly MimeType_image_x_rgb = "image/x-rgb";
  static readonly MimeType_image_x_xbitmap = "image/x-xbitmap";
  static readonly MimeType_image_x_xpixmap = "image/x-xpixmap";
  static readonly MimeType_image_x_xwindowdump = "image/x-xwindowdump";
  static readonly MimeType_model_iges = "model/iges";
  static readonly MimeType_model_mesh = "model/mesh";
  static readonly MimeType_text_calendar = "text/calendar";
  static readonly MimeType_text_comma_separated_values = "text/comma-separated-values";
  static readonly MimeType_text_css = "text/css";
  static readonly MimeType_text_html = "text/html";
  static readonly MimeType_text_h323 = "text/h323";
  static readonly MimeType_text_iuls = "text/iuls";
  static readonly MimeType_text_mathml = "text/mathml";
  static readonly MimeType_text_plain = "text/plain";
  static readonly MimeType_text_markdown = "text/markdown";
  static readonly MimeType_text_richtext = "text/richtext";
  static readonly MimeType_text_rtf = "text/rtf";
  static readonly MimeType_text_texmacs = "text/texmacs";
  static readonly MimeType_text_text = "text/text";
  static readonly MimeType_text_tab_separated_values = "text/tab-separated-values";
  static readonly MimeType_text_xml = "text/xml";
  static readonly MimeType_text_x_bibtex = "text/x-bibtex";
  static readonly MimeType_text_x_boo = "text/x-boo";
  static readonly MimeType_text_x_cpp_hdr = "text/x-c++hdr";
  static readonly MimeType_text_x_cpp_src = "text/x-c++src";
  static readonly MimeType_text_x_chdr = "text/x-chdr";
  static readonly MimeType_text_x_component = "text/x-component";
  static readonly MimeType_text_x_csh = "text/x-csh";
  static readonly MimeType_text_x_csrc = "text/x-csrc";
  static readonly MimeType_text_x_dsrc = "text/x-dsrc";
  static readonly MimeType_text_x_haskell = "text/x-haskell";
  static readonly MimeType_text_x_java = "text/x-java";
  static readonly MimeType_text_x_literate_haskell = "text/x-literate-haskell";
  static readonly MimeType_text_x_moc = "text/x-moc";
  static readonly MimeType_text_x_pascal = "text/x-pascal";
  static readonly MimeType_text_x_pcs_gcd = "text/x-pcs-gcd";
  static readonly MimeType_text_x_setext = "text/x-setext";
  static readonly MimeType_text_x_tcl = "text/x-tcl";
  static readonly MimeType_text_x_tex = "text/x-tex";
  static readonly MimeType_text_x_vcalendar = "text/x-vcalendar";
  static readonly MimeType_text_x_vcard = "text/x-vcard";
  static readonly MimeType_video_avc = "video/avc";
  static readonly MimeType_video_hevc = "video/hevc";
  static readonly MimeType_video_3gpp = "video/3gpp";
  static readonly MimeType_video_h263 = "video/3gpp";
  static readonly MimeType_video_dl = "video/dl";
  static readonly MimeType_video_dv = "video/dv";
  static readonly MimeType_video_fli = "video/fli";
  static readonly MimeType_video_m4v = "video/m4v";
  static readonly MimeType_video_mpeg = "video/mpeg";
  static readonly MimeType_video_mpeg4 = "video/mp4v-es";
  static readonly MimeType_video_mp4 = "video/mp4";
  static readonly MimeType_video_quicktime = "video/quicktime";
  static readonly MimeType_video_vnd_mpegurl = "video/vnd.mpegurl";
  static readonly MimeType_video_x_vnd_on2_vp8 = "video/x-vnd.on2.vp8";
  static readonly MimeType_video_x_vnd_on2_vp9 = "video/x-vnd.on2.vp9";
  static readonly MimeType_video_x_la_asf = "video/x-la-asf";
  static readonly MimeType_video_x_mng = "video/x-mng";
  static readonly MimeType_video_x_ms_asf = "video/x-ms-asf";
  static readonly MimeType_video_x_ms_wm = "video/x-ms-wm";
  static readonly MimeType_video_x_ms_wmv = "video/x-ms-wmv";
  static readonly MimeType_video_x_ms_wmx = "video/x-ms-wmx";
  static readonly MimeType_video_x_ms_wvx = "video/x-ms-wvx";
  static readonly MimeType_video_x_msvideo = "video/x-msvideo";
  static readonly MimeType_video_x_sgi_movie = "video/x-sgi-movie";
  static readonly MimeType_x_conference_x_cooltalk = "x-conference/x-cooltalk";
  static readonly MimeType_x_epoc_x_sisx_app = "x-epoc/x-sisx-app";
  static readonly MimeType_multipart_form_data = "multipart/form-data";
  static readonly MimeType_multipart_mixed = "multipart/mixed";
  static readonly MimeType_multipart_x_mixed_replace = "multipart/x-mixed-replace";

  /**
   * Singleton MIME-type map instance:
   */
  private static sMimeTypeMap: MimeTypeMap;
  /**
   * MIME-type to file extension mapping:
   */
  private readonly mMimeTypeToExtensionMap: Map<string, string> = new Map<string, string>();
  /**
   * File extension to MIME type mapping:
   */
  private readonly mExtensionToMimeTypeMap: Map<string, string> = new Map<string, string>();

  /**
   * Creates a new MIME-type map.
   */
  private constructor() {
  }

  /**
   * Returns the file extension or an empty string iff there is no
   * extension. This method is a convenience method for obtaining the
   * extension of a url and has undefined results for other Strings.
   *
   * @param url
   * @return The file extension of the given url.
   */
  static getFileExtensionFromUrl(url: string, default_: string = ""): string {
    if (url?.length > 0) {
      let query = url.lastIndexOf('?');
      if (query > 0) {
        url = url.substring(0, query);
      }
      let filenamePos = url.lastIndexOf('/');
      let filename =
        0 <= filenamePos ? url.substring(filenamePos + 1) : url;
      // if the filename contains special characters, we don't.html
      // consider it valid for our matching purposes:
      if (filename.length > 0 && /[a-zA-Z_0-9\\.\\-\\(\\)\\%]+/.test(filename)) {
        let dotPos = filename.lastIndexOf('.');
        if (0 <= dotPos) {
          return filename.substring(dotPos + 1);
        }
      }
    }
    return default_;
  }

  /**
   * Load an entry into the map. This does not check if the item already
   * exists, it trusts the caller!
   */
  private loadEntry(mimeType: string, extension: string) {
    //
    // if we have an existing x --> y mapping, we do not want to
    // override it with another mapping x --> ?
    // this is mostly because of the way the mime-type map below
    // is constructed (if a mime type maps to several extensions
    // the first extension is considered the most popular and is
    // added first; we do not want to overwrite it later).
    //
    if (!this.mMimeTypeToExtensionMap.has(mimeType)) {
      this.mMimeTypeToExtensionMap.set(mimeType, extension);
    }
    this.mExtensionToMimeTypeMap.set(extension, mimeType);
  }

  /**
   * Return true if the given MIME type has an entry in the map.
   *
   * @param mimeType A MIME type (i.e. text/plain)
   * @return True iff there is a mimeType entry in the map.
   */
  hasMimeType(mimeType: string): boolean {
    if (mimeType?.length > 0) {
      return this.mMimeTypeToExtensionMap.has(mimeType);
    }
    return false;
  }

  /**
   * Return the MIME type for the given extension.
   *
   * @param extension A file extension without the leading '.'
   * @return The MIME type for the given extension or null iff there is none.
   */
  getMimeTypeFromExtension(extension: string): string {
    if (extension?.length > 0) {
      return this.mExtensionToMimeTypeMap.get(extension);
    }
    return null;
  }

// Static method called by jni.
  static mimeTypeFromExtension(extension: string): string {
    return MimeTypeMap.getInstance().getMimeTypeFromExtension(extension);
  }

  /**
   * Return true if the given extension has a registered MIME type.
   *
   * @param extension A file extension without the leading '.'
   * @return True iff there is an extension entry in the map.
   */
  hasExtension(extension: string): boolean {
    if (extension?.length > 0) {
      return this.mExtensionToMimeTypeMap.has(extension);
    }
    return false;
  }

  /**
   * Return the registered extension for the given MIME type. Note that some
   * MIME types map to multiple extensions. This call will return the most
   * common extension for the given MIME type.
   *
   * @param mimeType A MIME type (i.e. text/plain)
   * @return The extension for the given MIME type or null iff there is none.
   */
  getExtensionFromMimeType(mimeType: string, defaultValue: string = null): string {
    if (mimeType?.length > 0) {
      return this.mMimeTypeToExtensionMap.get(mimeType);
    }
    return defaultValue;
  }

  /**
   * Get the singleton instance of MimeTypeMap.
   *
   * @return The singleton instance of the MIME-type map.
   */
  static getInstance(): MimeTypeMap {
    let sMimeTypeMap;
    if ((sMimeTypeMap = MimeTypeMap.sMimeTypeMap) == null) {
      MimeTypeMap.sMimeTypeMap = sMimeTypeMap = new MimeTypeMap();
      // The following table is based on /etc/mime.types data minus
      // chemical/* MIME types and MIME types that don't.html map to any
      // file extensions. We also exclude top-level domain names to
      // deal with cases like:
      //
      // mail.google.com/a/google.com
      //
      // and "active" MIME types (due to potential security issues).
      sMimeTypeMap.loadEntry("application/andrew-inset", "ez");
      sMimeTypeMap.loadEntry("application/dsptype", "tsp");
      sMimeTypeMap.loadEntry("application/futuresplash", "spl");
      sMimeTypeMap.loadEntry("application/hta", "hta");
      sMimeTypeMap.loadEntry("application/mac-binhex40", "hqx");
      sMimeTypeMap.loadEntry("application/mac-compactpro", "cpt");
      sMimeTypeMap.loadEntry("application/mathematica", "nb");
      sMimeTypeMap.loadEntry("application/msaccess", "mdb");
      sMimeTypeMap.loadEntry("application/oda", "oda");
      sMimeTypeMap.loadEntry("application/ogg", "ogg");
      sMimeTypeMap.loadEntry("application/pdf", "pdf");
      sMimeTypeMap.loadEntry("application/pgp-keys", "key");
      sMimeTypeMap.loadEntry("application/pgp-signature", "pgp");
      sMimeTypeMap.loadEntry("application/pics-rules", "prf");
      sMimeTypeMap.loadEntry("application/rar", "rar");
      sMimeTypeMap.loadEntry("application/rdf+xml", "rdf");
      sMimeTypeMap.loadEntry("application/rss+xml", "rss");
      sMimeTypeMap.loadEntry("application/zip", "zip");
      sMimeTypeMap.loadEntry("application/vnd.android.package-archive", "apk");
      sMimeTypeMap.loadEntry("application/vnd.cinderella", "cdy");
      sMimeTypeMap.loadEntry("application/vnd.ms-pki.stl", "stl");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.database", "odb");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.formula", "odf");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.graphics", "odg");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.graphics-template", "otg");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.image", "odi");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.spreadsheet", "ods");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.spreadsheet-template", "ots");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.text", "odt");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.text-master", "odm");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.text-template", "ott");
      sMimeTypeMap.loadEntry("application/vnd.oasis.opendocument.text-web", "oth");
      sMimeTypeMap.loadEntry("application/msword", "doc");
      sMimeTypeMap.loadEntry("application/msword", "dot");
      sMimeTypeMap.loadEntry("application/vnd.openxmlformats-officedocument.wordprocessingml.document", "docx");
      sMimeTypeMap.loadEntry("application/vnd.openxmlformats-officedocument.wordprocessingml.template", "dotx");
      sMimeTypeMap.loadEntry("application/vnd.ms-excel", "xls");
      sMimeTypeMap.loadEntry("application/vnd.ms-excel", "xlt");
      sMimeTypeMap.loadEntry("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx");
      sMimeTypeMap.loadEntry("application/vnd.openxmlformats-officedocument.spreadsheetml.template", "xltx");
      sMimeTypeMap.loadEntry("application/vnd.ms-powerpoint", "ppt");
      sMimeTypeMap.loadEntry("application/vnd.ms-powerpoint", "pot");
      sMimeTypeMap.loadEntry("application/vnd.ms-powerpoint", "pps");
      sMimeTypeMap.loadEntry("application/vnd.openxmlformats-officedocument.presentationml.presentation", "pptx");
      sMimeTypeMap.loadEntry("application/vnd.openxmlformats-officedocument.presentationml.template", "potx");
      sMimeTypeMap.loadEntry("application/vnd.openxmlformats-officedocument.presentationml.slideshow", "ppsx");
      sMimeTypeMap.loadEntry("application/vnd.rim.cod", "cod");
      sMimeTypeMap.loadEntry("application/vnd.smaf", "mmf");
      sMimeTypeMap.loadEntry("application/vnd.stardivision.calc", "sdc");
      sMimeTypeMap.loadEntry("application/vnd.stardivision.draw", "sda");
      sMimeTypeMap.loadEntry("application/vnd.stardivision.impress", "sdd");
      sMimeTypeMap.loadEntry("application/vnd.stardivision.impress", "sdp");
      sMimeTypeMap.loadEntry("application/vnd.stardivision.math", "smf");
      sMimeTypeMap.loadEntry("application/vnd.stardivision.writer", "sdw");
      sMimeTypeMap.loadEntry("application/vnd.stardivision.writer", "vor");
      sMimeTypeMap.loadEntry("application/vnd.stardivision.writer-global", "sgl");
      sMimeTypeMap.loadEntry("application/vnd.sun.xml.calc", "sxc");
      sMimeTypeMap.loadEntry("application/vnd.sun.xml.calc.template", "stc");
      sMimeTypeMap.loadEntry("application/vnd.sun.xml.draw", "sxd");
      sMimeTypeMap.loadEntry("application/vnd.sun.xml.draw.template", "std");
      sMimeTypeMap.loadEntry("application/vnd.sun.xml.impress", "sxi");
      sMimeTypeMap.loadEntry("application/vnd.sun.xml.impress.template", "sti");
      sMimeTypeMap.loadEntry("application/vnd.sun.xml.math", "sxm");
      sMimeTypeMap.loadEntry("application/vnd.sun.xml.writer", "sxw");
      sMimeTypeMap.loadEntry("application/vnd.sun.xml.writer.global", "sxg");
      sMimeTypeMap.loadEntry("application/vnd.sun.xml.writer.template", "stw");
      sMimeTypeMap.loadEntry("application/vnd.visio", "vsd");
      sMimeTypeMap.loadEntry("application/x-abiword", "abw");
      sMimeTypeMap.loadEntry("application/x-apple-diskimage", "dmg");
      sMimeTypeMap.loadEntry("application/x-bcpio", "bcpio");
      sMimeTypeMap.loadEntry("application/x-bittorrent", "torrent");
      sMimeTypeMap.loadEntry("application/x-cdf", "cdf");
      sMimeTypeMap.loadEntry("application/x-cdlink", "vcd");
      sMimeTypeMap.loadEntry("application/x-chess-pgn", "pgn");
      sMimeTypeMap.loadEntry("application/x-cpio", "cpio");
      sMimeTypeMap.loadEntry("application/x-debian-package", "deb");
      sMimeTypeMap.loadEntry("application/x-debian-package", "udeb");
      sMimeTypeMap.loadEntry("application/x-director", "dcr");
      sMimeTypeMap.loadEntry("application/x-director", "dir");
      sMimeTypeMap.loadEntry("application/x-director", "dxr");
      sMimeTypeMap.loadEntry("application/x-dms", "dms");
      sMimeTypeMap.loadEntry("application/x-doom", "wad");
      sMimeTypeMap.loadEntry("application/x-dvi", "dvi");
      sMimeTypeMap.loadEntry("application/x-flac", "flac");
      sMimeTypeMap.loadEntry("application/x-font", "pfa");
      sMimeTypeMap.loadEntry("application/x-font", "pfb");
      sMimeTypeMap.loadEntry("application/x-font", "gsf");
      sMimeTypeMap.loadEntry("application/x-font", "pcf");
      sMimeTypeMap.loadEntry("application/x-font", "pcf.Z");
      sMimeTypeMap.loadEntry("application/x-freemind", "mm");
      sMimeTypeMap.loadEntry("application/x-futuresplash", "spl");
      sMimeTypeMap.loadEntry("application/x-gnumeric", "gnumeric");
      sMimeTypeMap.loadEntry("application/x-go-sgf", "sgf");
      sMimeTypeMap.loadEntry("application/x-graphing-calculator", "gcf");
      sMimeTypeMap.loadEntry("application/x-gtar", "gtar");
      sMimeTypeMap.loadEntry("application/x-gtar", "tgz");
      sMimeTypeMap.loadEntry("application/x-gtar", "taz");
      sMimeTypeMap.loadEntry("application/x-hdf", "hdf");
      sMimeTypeMap.loadEntry("application/x-ica", "ica");
      sMimeTypeMap.loadEntry("application/x-internet-signup", "ins");
      sMimeTypeMap.loadEntry("application/x-internet-signup", "isp");
      sMimeTypeMap.loadEntry("application/x-iphone", "iii");
      sMimeTypeMap.loadEntry("application/x-iso9660-image", "iso");
      sMimeTypeMap.loadEntry("application/x-jmol", "jmz");
      sMimeTypeMap.loadEntry("application/x-kchart", "chrt");
      sMimeTypeMap.loadEntry("application/x-killustrator", "kil");
      sMimeTypeMap.loadEntry("application/x-koan", "skp");
      sMimeTypeMap.loadEntry("application/x-koan", "skd");
      sMimeTypeMap.loadEntry("application/x-koan", "skt");
      sMimeTypeMap.loadEntry("application/x-koan", "skm");
      sMimeTypeMap.loadEntry("application/x-kpresenter", "kpr");
      sMimeTypeMap.loadEntry("application/x-kpresenter", "kpt");
      sMimeTypeMap.loadEntry("application/x-kspread", "ksp");
      sMimeTypeMap.loadEntry("application/x-kword", "kwd");
      sMimeTypeMap.loadEntry("application/x-kword", "kwt");
      sMimeTypeMap.loadEntry("application/x-latex", "latex");
      sMimeTypeMap.loadEntry("application/x-lha", "lha");
      sMimeTypeMap.loadEntry("application/x-lzh", "lzh");
      sMimeTypeMap.loadEntry("application/x-lzx", "lzx");
      sMimeTypeMap.loadEntry("application/x-maker", "frm");
      sMimeTypeMap.loadEntry("application/x-maker", "maker");
      sMimeTypeMap.loadEntry("application/x-maker", "frame");
      sMimeTypeMap.loadEntry("application/x-maker", "fb");
      sMimeTypeMap.loadEntry("application/x-maker", "category");
      sMimeTypeMap.loadEntry("application/x-maker", "fbdoc");
      sMimeTypeMap.loadEntry("application/x-mif", "mif");
      sMimeTypeMap.loadEntry("application/x-ms-wmd", "wmd");
      sMimeTypeMap.loadEntry("application/x-ms-wmz", "wmz");
      sMimeTypeMap.loadEntry("application/x-msi", "msi");
      sMimeTypeMap.loadEntry("application/x-ns-proxy-autoconfig", "pac");
      sMimeTypeMap.loadEntry("application/x-nwc", "nwc");
      sMimeTypeMap.loadEntry("application/x-object", "o");
      sMimeTypeMap.loadEntry("application/x-oz-application", "oza");
      sMimeTypeMap.loadEntry("application/x-pkcs12", "p12");
      sMimeTypeMap.loadEntry("application/x-pkcs7-certreqresp", "p7r");
      sMimeTypeMap.loadEntry("application/x-pkcs7-crl", "crl");
      sMimeTypeMap.loadEntry("application/x-quicktimeplayer", "qtl");
      sMimeTypeMap.loadEntry("application/x-shar", "shar");
      sMimeTypeMap.loadEntry("application/x-shockwave-flash", "swf");
      sMimeTypeMap.loadEntry("application/x-stuffit", "sit");
      sMimeTypeMap.loadEntry("application/x-sv4cpio", "sv4cpio");
      sMimeTypeMap.loadEntry("application/x-sv4crc", "sv4crc");
      sMimeTypeMap.loadEntry("application/x-tar", "tar");
      sMimeTypeMap.loadEntry("application/x-texinfo", "texinfo");
      sMimeTypeMap.loadEntry("application/x-texinfo", "texi");
      sMimeTypeMap.loadEntry("application/x-troff", "t");
      sMimeTypeMap.loadEntry("application/x-troff", "roff");
      sMimeTypeMap.loadEntry("application/x-troff-man", "man");
      sMimeTypeMap.loadEntry("application/x-ustar", "ustar");
      sMimeTypeMap.loadEntry("application/x-wais-source", "src");
      sMimeTypeMap.loadEntry("application/x-wingz", "wz");
      sMimeTypeMap.loadEntry("application/x-webarchive", "webarchive");
      sMimeTypeMap.loadEntry("application/x-x509-ca-cert", "crt");
      sMimeTypeMap.loadEntry("application/x-x509-user-cert", "crt");
      sMimeTypeMap.loadEntry("application/x-xcf", "xcf");
      sMimeTypeMap.loadEntry("application/x-xfig", "fig");
      sMimeTypeMap.loadEntry("application/xhtml+xml", "xhtml");
      sMimeTypeMap.loadEntry("audio/3gpp", "3gpp");
      sMimeTypeMap.loadEntry("audio/basic", "snd");
      sMimeTypeMap.loadEntry("audio/midi", "mid");
      sMimeTypeMap.loadEntry("audio/midi", "midi");
      sMimeTypeMap.loadEntry("audio/midi", "kar");
      sMimeTypeMap.loadEntry("audio/mpeg", "m4a");
      sMimeTypeMap.loadEntry("audio/mpeg", "mp3");
      sMimeTypeMap.loadEntry("audio/mpeg", "mpga");
      sMimeTypeMap.loadEntry("audio/mpeg", "mpega");
      sMimeTypeMap.loadEntry("audio/mpeg", "mp2");
      sMimeTypeMap.loadEntry("audio/mpegurl", "m3u");
      sMimeTypeMap.loadEntry("audio/prs.sid", "sid");
      sMimeTypeMap.loadEntry("audio/x-aiff", "aif");
      sMimeTypeMap.loadEntry("audio/x-aiff", "aiff");
      sMimeTypeMap.loadEntry("audio/x-aiff", "aifc");
      sMimeTypeMap.loadEntry("audio/x-gsm", "gsm");
      sMimeTypeMap.loadEntry("audio/x-mpegurl", "m3u");
      sMimeTypeMap.loadEntry("audio/x-ms-wma", "wma");
      sMimeTypeMap.loadEntry("audio/x-ms-wax", "wax");
      sMimeTypeMap.loadEntry("audio/x-pn-realaudio", "ra");
      sMimeTypeMap.loadEntry("audio/x-pn-realaudio", "rm");
      sMimeTypeMap.loadEntry("audio/x-pn-realaudio", "ram");
      sMimeTypeMap.loadEntry("audio/x-realaudio", "ra");
      sMimeTypeMap.loadEntry("audio/x-scpls", "pls");
      sMimeTypeMap.loadEntry("audio/x-sd2", "sd2");
      sMimeTypeMap.loadEntry("audio/x-wav", "wav");
      sMimeTypeMap.loadEntry("image/bmp", "bmp");
      sMimeTypeMap.loadEntry("image/gif", "gif");
      sMimeTypeMap.loadEntry("image/ico", "cur");
      sMimeTypeMap.loadEntry("image/ico", "ico");
      sMimeTypeMap.loadEntry("image/ief", "ief");
      sMimeTypeMap.loadEntry("image/jpeg", "jpeg");
      sMimeTypeMap.loadEntry("image/jpeg", "jpg");
      sMimeTypeMap.loadEntry("image/jpeg", "jpe");
      sMimeTypeMap.loadEntry("image/pcx", "pcx");
      sMimeTypeMap.loadEntry("image/png", "png");
      sMimeTypeMap.loadEntry("image/svg+xml", "svg");
      sMimeTypeMap.loadEntry("image/svg+xml", "svgz");
      sMimeTypeMap.loadEntry("image/tiff", "tiff");
      sMimeTypeMap.loadEntry("image/tiff", "tif");
      sMimeTypeMap.loadEntry("image/vnd.djvu", "djvu");
      sMimeTypeMap.loadEntry("image/vnd.djvu", "djv");
      sMimeTypeMap.loadEntry("image/vnd.wap.wbmp", "wbmp");
      sMimeTypeMap.loadEntry("image/webp", "webp");
      sMimeTypeMap.loadEntry("image/x-cmu-raster", "ras");
      sMimeTypeMap.loadEntry("image/x-coreldraw", "cdr");
      sMimeTypeMap.loadEntry("image/x-coreldrawpattern", "pat");
      sMimeTypeMap.loadEntry("image/x-coreldrawtemplate", "cdt");
      sMimeTypeMap.loadEntry("image/x-corelphotopaint", "cpt");
      sMimeTypeMap.loadEntry("image/x-icon", "ico");
      sMimeTypeMap.loadEntry("image/x-jg", "art");
      sMimeTypeMap.loadEntry("image/x-jng", "jng");
      sMimeTypeMap.loadEntry("image/x-ms-bmp", "bmp");
      sMimeTypeMap.loadEntry("image/x-photoshop", "psd");
      sMimeTypeMap.loadEntry("image/x-portable-anymap", "pnm");
      sMimeTypeMap.loadEntry("image/x-portable-bitmap", "pbm");
      sMimeTypeMap.loadEntry("image/x-portable-graymap", "pgm");
      sMimeTypeMap.loadEntry("image/x-portable-pixmap", "ppm");
      sMimeTypeMap.loadEntry("image/x-rgb", "rgb");
      sMimeTypeMap.loadEntry("image/x-xbitmap", "xbm");
      sMimeTypeMap.loadEntry("image/x-xpixmap", "xpm");
      sMimeTypeMap.loadEntry("image/x-xwindowdump", "xwd");
      sMimeTypeMap.loadEntry("model/iges", "igs");
      sMimeTypeMap.loadEntry("model/iges", "iges");
      sMimeTypeMap.loadEntry("model/mesh", "msh");
      sMimeTypeMap.loadEntry("model/mesh", "mesh");
      sMimeTypeMap.loadEntry("model/mesh", "silo");
      sMimeTypeMap.loadEntry("text/calendar", "ics");
      sMimeTypeMap.loadEntry("text/calendar", "icz");
      sMimeTypeMap.loadEntry("text/comma-separated-values", "csv");
      sMimeTypeMap.loadEntry("text/css", "css");
      sMimeTypeMap.loadEntry("text/html", "htm");
      sMimeTypeMap.loadEntry("text/html", "html");
      sMimeTypeMap.loadEntry("text/h323", "323");
      sMimeTypeMap.loadEntry("text/iuls", "uls");
      sMimeTypeMap.loadEntry("text/mathml", "mml");
      // add it first so it will be the default for ExtensionFromMimeType
      sMimeTypeMap.loadEntry("text/plain", "txt");
      sMimeTypeMap.loadEntry("text/plain", "asc");
      sMimeTypeMap.loadEntry("text/plain", "text");
      sMimeTypeMap.loadEntry("text/plain", "diff");
      sMimeTypeMap.loadEntry("text/plain", "po");     // reserve "pot" for vnd.ms-powerpoint
      sMimeTypeMap.loadEntry("text/markdown", "md");
      sMimeTypeMap.loadEntry("text/richtext", "rtx");
      sMimeTypeMap.loadEntry("text/rtf", "rtf");
      sMimeTypeMap.loadEntry("text/texmacs", "ts");
      sMimeTypeMap.loadEntry("text/text", "phps");
      sMimeTypeMap.loadEntry("text/tab-separated-values", "tsv");
      sMimeTypeMap.loadEntry("text/xml", "xml");
      sMimeTypeMap.loadEntry("text/x-bibtex", "bib");
      sMimeTypeMap.loadEntry("text/x-boo", "boo");
      sMimeTypeMap.loadEntry("text/x-c++hdr", "h++");
      sMimeTypeMap.loadEntry("text/x-c++hdr", "hpp");
      sMimeTypeMap.loadEntry("text/x-c++hdr", "hxx");
      sMimeTypeMap.loadEntry("text/x-c++hdr", "hh");
      sMimeTypeMap.loadEntry("text/x-c++src", "c++");
      sMimeTypeMap.loadEntry("text/x-c++src", "cpp");
      sMimeTypeMap.loadEntry("text/x-c++src", "cxx");
      sMimeTypeMap.loadEntry("text/x-chdr", "h");
      sMimeTypeMap.loadEntry("text/x-component", "htc");
      sMimeTypeMap.loadEntry("text/x-csh", "csh");
      sMimeTypeMap.loadEntry("text/x-csrc", "c");
      sMimeTypeMap.loadEntry("text/x-dsrc", "d");
      sMimeTypeMap.loadEntry("text/x-haskell", "hs");
      sMimeTypeMap.loadEntry("text/x-java", "java");
      sMimeTypeMap.loadEntry("text/x-literate-haskell", "lhs");
      sMimeTypeMap.loadEntry("text/x-moc", "moc");
      sMimeTypeMap.loadEntry("text/x-pascal", "p");
      sMimeTypeMap.loadEntry("text/x-pascal", "pas");
      sMimeTypeMap.loadEntry("text/x-pcs-gcd", "gcd");
      sMimeTypeMap.loadEntry("text/x-setext", "etx");
      sMimeTypeMap.loadEntry("text/x-tcl", "tcl");
      sMimeTypeMap.loadEntry("text/x-tex", "tex");
      sMimeTypeMap.loadEntry("text/x-tex", "ltx");
      sMimeTypeMap.loadEntry("text/x-tex", "sty");
      sMimeTypeMap.loadEntry("text/x-tex", "cls");
      sMimeTypeMap.loadEntry("text/x-vcalendar", "vcs");
      sMimeTypeMap.loadEntry("text/x-vcard", "vcf");
      sMimeTypeMap.loadEntry("video/3gpp", "3gp");
      sMimeTypeMap.loadEntry("video/3gpp", "3g2");
      sMimeTypeMap.loadEntry("video/dl", "dl");
      sMimeTypeMap.loadEntry("video/dv", "dif");
      sMimeTypeMap.loadEntry("video/dv", "dv");
      sMimeTypeMap.loadEntry("video/fli", "fli");
      sMimeTypeMap.loadEntry("video/m4v", "m4v");
      sMimeTypeMap.loadEntry("video/mpeg", "mpeg");
      sMimeTypeMap.loadEntry("video/mpeg", "mpg");
      sMimeTypeMap.loadEntry("video/mpeg", "mpe");
      sMimeTypeMap.loadEntry("video/mp4", "mp4");
      sMimeTypeMap.loadEntry("video/mpeg", "VOB");
      sMimeTypeMap.loadEntry("video/quicktime", "qt");
      sMimeTypeMap.loadEntry("video/quicktime", "mov");
      sMimeTypeMap.loadEntry("video/vnd.mpegurl", "mxu");
      sMimeTypeMap.loadEntry("video/x-la-asf", "lsf");
      sMimeTypeMap.loadEntry("video/x-la-asf", "lsx");
      sMimeTypeMap.loadEntry("video/x-mng", "mng");
      sMimeTypeMap.loadEntry("video/x-ms-asf", "asf");
      sMimeTypeMap.loadEntry("video/x-ms-asf", "asx");
      sMimeTypeMap.loadEntry("video/x-ms-wm", "wm");
      sMimeTypeMap.loadEntry("video/x-ms-wmv", "wmv");
      sMimeTypeMap.loadEntry("video/x-ms-wmx", "wmx");
      sMimeTypeMap.loadEntry("video/x-ms-wvx", "wvx");
      sMimeTypeMap.loadEntry("video/x-msvideo", "avi");
      sMimeTypeMap.loadEntry("application/vnd.android.package-archive", "apk");
      sMimeTypeMap.loadEntry("video/x-sgi-movie", "movie");
      sMimeTypeMap.loadEntry("x-conference/x-cooltalk", "ice");
      sMimeTypeMap.loadEntry("x-epoc/x-sisx-app", "sisx");
    }
    return sMimeTypeMap;
  }

  static isText(mimeType: string): boolean {
    return mimeType?.startsWith("text/");
  }

  static isDocument(mimeType: string): boolean {
    return mimeType?.startsWith("application/");
  }

  static isImage(mimeType: string): boolean {
    return mimeType?.startsWith("image/");
  }

  static isAudio(mimeType: string): boolean {
    return mimeType?.startsWith("audio/");
  }

  static isVideo(mimeType: string): boolean {
    return mimeType?.startsWith("video/");
  }

  static isMedia(mimeType: string): boolean {
    return MimeTypeMap.isImage(mimeType) || MimeTypeMap.isAudio(mimeType) || MimeTypeMap.isVideo(mimeType);
  }

  static isJson(mimeType: string): boolean {
    return mimeType != null && mimeType === MimeTypeMap.MimeType_application_json;
  }

  static getIconForMimeType(mimeType: string, hints?: any): typeof SvgIcon {
    if (this.isText(mimeType)) {
      if (hints?.link) {
        return LinkOutlined;
      }
      return Title;
    }
    if (this.isImage(mimeType)) {
      return Image;
    }
    if (this.isVideo(mimeType)) {
      return Movie;
    }
    if (this.isAudio(mimeType)) {
      return MusicNote;
    }
    if (this.isDocument(mimeType)) {
      return InsertDriveFile;
    }
    if (hints?.folder) {
      return Folder;
    }
    return LiveHelp;
  }

  static getColorForMimeType(mimeType: string, hints?: any): string {
    if (this.isText(mimeType)) {
      if (hints?.link) {
        return colorOrange;
      }
      return black;
    }
    if (this.isImage(mimeType)) {
      return colorRed;
    }
    if (this.isVideo(mimeType)) {
      return colorLightGreen;
    }
    if (this.isAudio(mimeType)) {
      return colorDarkPurple;
    }
    if (this.isDocument(mimeType)) {
      return colorBlue;
    }
    if (hints?.folder) {
      return colorBlue;
    }
    return mediumGray;
  }
}
