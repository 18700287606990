import {black, white} from "./colors";
import {PD_XSM, SZ_JUMBO, SZ_LG, SZ_MD, SZ_SM, SZ_SMD, SZ_SSM, SZ_XSM, SZ_XXLG, SZ_XXSM, SZ_XXXLG} from "./dimens";
import {$KTS} from "./keytext";
import {SvgIcon} from "@mui/material";
import {CloudDoneOutlined, SyncOutlined, WarningOutlined} from "@mui/icons-material";

export const PROVISIONING_CODE_MIN_LENGTH = 8;
export const PROVISIONING_CODE_MAX_LENGTH = 12;

export const SERVER_USERNAME = "$";

export const TYPING_MAX_DELAY = 2000;
export const TYPING_EXPIRE_DURATION = TYPING_MAX_DELAY + 1000;

export const PROFILE_PHOTO_STYLE = {width: SZ_XXLG, height: SZ_XXLG};
export const PROFILE_PHOTO_STYLE_MD = {width: SZ_MD, height: SZ_MD};
export const PROFILE_PHOTO_STYLE_XXLG = {width: SZ_XXLG, height: SZ_XXLG};

export const AVATAR_SSM_STYLE = {width: SZ_SSM, height: SZ_SSM};
export const AVATAR_SM_STYLE = {width: SZ_SM, height: SZ_SM};
export const AVATAR_SMD_STYLE = {width: SZ_SMD, height: SZ_SMD};
export const AVATAR_MD_STYLE = {width: SZ_MD, height: SZ_MD};
export const AVATAR_MD_STYLE_MR = {width: SZ_MD, height: SZ_MD, margin: PD_XSM};
export const AVATAR_LG_STYLE_MR = {width: SZ_LG, height: SZ_LG, margin: PD_XSM};
export const AVATAR_JUMBO_STYLE_MR = {width: SZ_JUMBO, height: SZ_JUMBO, margin: PD_XSM};

export const ICON_BUTTON_MD_STYLE = {color: black, width: SZ_MD, height: SZ_MD};
export const ICON_BUTTON_SM_STYLE = {color: black, width: SZ_SM, height: SZ_SM};
export const ICON_BUTTON_SM_STYLE_PD_SM = {color: black, width: SZ_SM, height: SZ_SM, padding: '4px'};
export const ICON_BUTTON_LIGHT_SM_STYLE_PD_SM = {color: white, width: SZ_SM, height: SZ_SM, padding: '4px'};
export const ICON_BUTTON_XXSM_STYLE_PD = {color: black, width: SZ_XXSM, height: SZ_XXSM, padding: '4px'};
export const ICON_BUTTON_XSM_STYLE_PD = {color: black, width: SZ_XSM, height: SZ_XSM, padding: '4px'};
export const ICON_BUTTON_SM_STYLE_PD = {color: black, width: SZ_SM, height: SZ_SM};
export const ICON_BUTTON_LIGHT_SM_STYLE_PD = {color: white, width: SZ_SM, height: SZ_SM, padding: '12px'};
export const ICON_BUTTON_LIGHT_XSM_STYLE = {color: white, width: SZ_XSM, height: SZ_XSM};
export const ICON_BUTTON_XSM_STYLE = {color: black, width: SZ_XSM, height: SZ_XSM};

export const ORIENTATION_WIDE = "wide";
export const ORIENTATION_WIDE_ASPECT_RATIO = 1.33;
export const ORIENTATION_SQUARE = "square";
export const ORIENTATION_SQUARE_ASPECT_RATIO = 1;
export const ORIENTATION_TALL = "tall";
export const ORIENTATION_TALL_ASPECT_RATIO = 0.75;

export function getAspectRatioForOrientation(orientation: string): number {
  switch (orientation) {
    case ORIENTATION_WIDE:
      return ORIENTATION_WIDE_ASPECT_RATIO;
    case ORIENTATION_TALL:
      return ORIENTATION_TALL_ASPECT_RATIO;
    case ORIENTATION_SQUARE:
    default:
      return ORIENTATION_SQUARE_ASPECT_RATIO;
  }
}

export const TIMEZONE_ENUM_VALUES = [
  $KTS("africa_abidjan", "Africa/Abidjan"),
  $KTS("africa_accra", "Africa/Accra"),
  $KTS("africa_addis_ababa", "Africa/Addis_Ababa"),
  $KTS("africa_algiers", "Africa/Algiers"),
  $KTS("africa_asmara", "Africa/Asmara"),
  $KTS("africa_bamako", "Africa/Bamako"),
  $KTS("africa_bangui", "Africa/Bangui"),
  $KTS("africa_banjul", "Africa/Banjul"),
  $KTS("africa_bissau", "Africa/Bissau"),
  $KTS("africa_blantyre", "Africa/Blantyre"),
  $KTS("africa_brazzaville", "Africa/Brazzaville"),
  $KTS("africa_bujumbura", "Africa/Bujumbura"),
  $KTS("africa_cairo", "Africa/Cairo"),
  $KTS("africa_casablanca", "Africa/Casablanca"),
  $KTS("africa_ceuta", "Africa/Ceuta"),
  $KTS("africa_conakry", "Africa/Conakry"),
  $KTS("africa_dakar", "Africa/Dakar"),
  $KTS("africa_dar_es_salaam", "Africa/Dar_es_Salaam"),
  $KTS("africa_djibouti", "Africa/Djibouti"),
  $KTS("africa_douala", "Africa/Douala"),
  $KTS("africa_el_aaiun", "Africa/El_Aaiun"),
  $KTS("africa_freetown", "Africa/Freetown"),
  $KTS("africa_gaborone", "Africa/Gaborone"),
  $KTS("africa_harare", "Africa/Harare"),
  $KTS("africa_johannesburg", "Africa/Johannesburg"),
  $KTS("africa_juba", "Africa/Juba"),
  $KTS("africa_kampala", "Africa/Kampala"),
  $KTS("africa_khartoum", "Africa/Khartoum"),
  $KTS("africa_kigali", "Africa/Kigali"),
  $KTS("africa_kinshasa", "Africa/Kinshasa"),
  $KTS("africa_lagos", "Africa/Lagos"),
  $KTS("africa_libreville", "Africa/Libreville"),
  $KTS("africa_lome", "Africa/Lome"),
  $KTS("africa_luanda", "Africa/Luanda"),
  $KTS("africa_lubumbashi", "Africa/Lubumbashi"),
  $KTS("africa_lusaka", "Africa/Lusaka"),
  $KTS("africa_malabo", "Africa/Malabo"),
  $KTS("africa_maputo", "Africa/Maputo"),
  $KTS("africa_maseru", "Africa/Maseru"),
  $KTS("africa_mbabane", "Africa/Mbabane"),
  $KTS("africa_mogadishu", "Africa/Mogadishu"),
  $KTS("africa_monrovia", "Africa/Monrovia"),
  $KTS("africa_nairobi", "Africa/Nairobi"),
  $KTS("africa_ndjamena", "Africa/Ndjamena"),
  $KTS("africa_niamey", "Africa/Niamey"),
  $KTS("africa_nouakchott", "Africa/Nouakchott"),
  $KTS("africa_ouagadougou", "Africa/Ouagadougou"),
  $KTS("africa_porto_novo", "Africa/Porto-Novo"),
  $KTS("africa_sao_tome", "Africa/Sao_Tome"),
  $KTS("africa_tripoli", "Africa/Tripoli"),
  $KTS("africa_tunis", "Africa/Tunis"),
  $KTS("africa_windhoek", "Africa/Windhoek"),
  $KTS("america_adak", "America/Adak"),
  $KTS("america_anchorage", "America/Anchorage"),
  $KTS("america_anguilla", "America/Anguilla"),
  $KTS("america_antigua", "America/Antigua"),
  $KTS("america_araguaina", "America/Araguaina"),
  $KTS("america_argentina_buenos_aires", "America/Argentina/Buenos_Aires"),
  $KTS("america_argentina_catamarca", "America/Argentina/Catamarca"),
  $KTS("america_argentina_cordoba", "America/Argentina/Cordoba"),
  $KTS("america_argentina_jujuy", "America/Argentina/Jujuy"),
  $KTS("america_argentina_la_rioja", "America/Argentina/La_Rioja"),
  $KTS("america_argentina_mendoza", "America/Argentina/Mendoza"),
  $KTS("america_argentina_rio_gallegos", "America/Argentina/Rio_Gallegos"),
  $KTS("america_argentina_salta", "America/Argentina/Salta"),
  $KTS("america_argentina_san_juan", "America/Argentina/San_Juan"),
  $KTS("america_argentina_san_luis", "America/Argentina/San_Luis"),
  $KTS("america_argentina_tucuman", "America/Argentina/Tucuman"),
  $KTS("america_argentina_ushuaia", "America/Argentina/Ushuaia"),
  $KTS("america_aruba", "America/Aruba"),
  $KTS("america_asuncion", "America/Asuncion"),
  $KTS("america_atikokan", "America/Atikokan"),
  $KTS("america_bahia", "America/Bahia"),
  $KTS("america_bahia_banderas", "America/Bahia_Banderas"),
  $KTS("america_barbados", "America/Barbados"),
  $KTS("america_belem", "America/Belem"),
  $KTS("america_belize", "America/Belize"),
  $KTS("america_blanc_sablon", "America/Blanc-Sablon"),
  $KTS("america_boa_vista", "America/Boa_Vista"),
  $KTS("america_bogota", "America/Bogota"),
  $KTS("america_boise", "America/Boise"),
  $KTS("america_cambridge_bay", "America/Cambridge_Bay"),
  $KTS("america_campo_grande", "America/Campo_Grande"),
  $KTS("america_cancun", "America/Cancun"),
  $KTS("america_caracas", "America/Caracas"),
  $KTS("america_cayenne", "America/Cayenne"),
  $KTS("america_cayman", "America/Cayman"),
  $KTS("america_chicago", "America/Chicago"),
  $KTS("america_chihuahua", "America/Chihuahua"),
  $KTS("america_costa_rica", "America/Costa_Rica"),
  $KTS("america_creston", "America/Creston"),
  $KTS("america_cuiaba", "America/Cuiaba"),
  $KTS("america_curacao", "America/Curacao"),
  $KTS("america_danmarkshavn", "America/Danmarkshavn"),
  $KTS("america_dawson", "America/Dawson"),
  $KTS("america_dawson_creek", "America/Dawson_Creek"),
  $KTS("america_denver", "America/Denver"),
  $KTS("america_detroit", "America/Detroit"),
  $KTS("america_dominica", "America/Dominica"),
  $KTS("america_edmonton", "America/Edmonton"),
  $KTS("america_eirunepe", "America/Eirunepe"),
  $KTS("america_el_salvador", "America/El_Salvador"),
  $KTS("america_fortaleza", "America/Fortaleza"),
  $KTS("america_glace_bay", "America/Glace_Bay"),
  $KTS("america_godthab", "America/Godthab"),
  $KTS("america_goose_bay", "America/Goose_Bay"),
  $KTS("america_grand_turk", "America/Grand_Turk"),
  $KTS("america_grenada", "America/Grenada"),
  $KTS("america_guadeloupe", "America/Guadeloupe"),
  $KTS("america_guatemala", "America/Guatemala"),
  $KTS("america_guayaquil", "America/Guayaquil"),
  $KTS("america_guyana", "America/Guyana"),
  $KTS("america_halifax", "America/Halifax"),
  $KTS("america_havana", "America/Havana"),
  $KTS("america_hermosillo", "America/Hermosillo"),
  $KTS("america_indiana_indianapolis", "America/Indiana/Indianapolis"),
  $KTS("america_indiana_knox", "America/Indiana/Knox"),
  $KTS("america_indiana_marengo", "America/Indiana/Marengo"),
  $KTS("america_indiana_petersburg", "America/Indiana/Petersburg"),
  $KTS("america_indiana_tell_city", "America/Indiana/Tell_City"),
  $KTS("america_indiana_vevay", "America/Indiana/Vevay"),
  $KTS("america_indiana_vincennes", "America/Indiana/Vincennes"),
  $KTS("america_indiana_winamac", "America/Indiana/Winamac"),
  $KTS("america_inuvik", "America/Inuvik"),
  $KTS("america_iqaluit", "America/Iqaluit"),
  $KTS("america_jamaica", "America/Jamaica"),
  $KTS("america_juneau", "America/Juneau"),
  $KTS("america_kentucky_louisville", "America/Kentucky/Louisville"),
  $KTS("america_kentucky_monticello", "America/Kentucky/Monticello"),
  $KTS("america_kralendijk", "America/Kralendijk"),
  $KTS("america_la_paz", "America/La_Paz"),
  $KTS("america_lima", "America/Lima"),
  $KTS("america_los_angeles", "America/Los_Angeles"),
  $KTS("america_lower_princes", "America/Lower_Princes"),
  $KTS("america_maceio", "America/Maceio"),
  $KTS("america_managua", "America/Managua"),
  $KTS("america_manaus", "America/Manaus"),
  $KTS("america_marigot", "America/Marigot"),
  $KTS("america_martinique", "America/Martinique"),
  $KTS("america_matamoros", "America/Matamoros"),
  $KTS("america_mazatlan", "America/Mazatlan"),
  $KTS("america_menominee", "America/Menominee"),
  $KTS("america_merida", "America/Merida"),
  $KTS("america_metlakatla", "America/Metlakatla"),
  $KTS("america_mexico_city", "America/Mexico_City"),
  $KTS("america_miquelon", "America/Miquelon"),
  $KTS("america_moncton", "America/Moncton"),
  $KTS("america_monterrey", "America/Monterrey"),
  $KTS("america_montevideo", "America/Montevideo"),
  $KTS("america_montreal", "America/Montreal"),
  $KTS("america_montserrat", "America/Montserrat"),
  $KTS("america_nassau", "America/Nassau"),
  $KTS("america_new_york", "America/New_York"),
  $KTS("america_nipigon", "America/Nipigon"),
  $KTS("america_nome", "America/Nome"),
  $KTS("america_noronha", "America/Noronha"),
  $KTS("america_north_dakota_beulah", "America/North_Dakota/Beulah"),
  $KTS("america_north_dakota_center", "America/North_Dakota/Center"),
  $KTS("america_north_dakota_new_salem", "America/North_Dakota/New_Salem"),
  $KTS("america_ojinaga", "America/Ojinaga"),
  $KTS("america_panama", "America/Panama"),
  $KTS("america_pangnirtung", "America/Pangnirtung"),
  $KTS("america_paramaribo", "America/Paramaribo"),
  $KTS("america_phoenix", "America/Phoenix"),
  $KTS("america_port_au_prince", "America/Port-au-Prince"),
  $KTS("america_port_of_spain", "America/Port_of_Spain"),
  $KTS("america_porto_velho", "America/Porto_Velho"),
  $KTS("america_puerto_rico", "America/Puerto_Rico"),
  $KTS("america_rainy_river", "America/Rainy_River"),
  $KTS("america_rankin_inlet", "America/Rankin_Inlet"),
  $KTS("america_recife", "America/Recife"),
  $KTS("america_regina", "America/Regina"),
  $KTS("america_resolute", "America/Resolute"),
  $KTS("america_rio_branco", "America/Rio_Branco"),
  $KTS("america_santa_isabel", "America/Santa_Isabel"),
  $KTS("america_santarem", "America/Santarem"),
  $KTS("america_santiago", "America/Santiago"),
  $KTS("america_santo_domingo", "America/Santo_Domingo"),
  $KTS("america_sao_paulo", "America/Sao_Paulo"),
  $KTS("america_scoresbysund", "America/Scoresbysund"),
  $KTS("america_shiprock", "America/Shiprock"),
  $KTS("america_sitka", "America/Sitka"),
  $KTS("america_st_barthelemy", "America/St_Barthelemy"),
  $KTS("america_st_johns", "America/St_Johns"),
  $KTS("america_st_kitts", "America/St_Kitts"),
  $KTS("america_st_lucia", "America/St_Lucia"),
  $KTS("america_st_thomas", "America/St_Thomas"),
  $KTS("america_st_vincent", "America/St_Vincent"),
  $KTS("america_swift_current", "America/Swift_Current"),
  $KTS("america_tegucigalpa", "America/Tegucigalpa"),
  $KTS("america_thule", "America/Thule"),
  $KTS("america_thunder_bay", "America/Thunder_Bay"),
  $KTS("america_tijuana", "America/Tijuana"),
  $KTS("america_toronto", "America/Toronto"),
  $KTS("america_tortola", "America/Tortola"),
  $KTS("america_vancouver", "America/Vancouver"),
  $KTS("america_whitehorse", "America/Whitehorse"),
  $KTS("america_winnipeg", "America/Winnipeg"),
  $KTS("america_yakutat", "America/Yakutat"),
  $KTS("america_yellowknife", "America/Yellowknife"),
  $KTS("antarctica_casey", "Antarctica/Casey"),
  $KTS("antarctica_davis", "Antarctica/Davis"),
  $KTS("antarctica_dumontdurville", "Antarctica/DumontDUrville"),
  $KTS("antarctica_macquarie", "Antarctica/Macquarie"),
  $KTS("antarctica_mawson", "Antarctica/Mawson"),
  $KTS("antarctica_mcmurdo", "Antarctica/McMurdo"),
  $KTS("antarctica_palmer", "Antarctica/Palmer"),
  $KTS("antarctica_rothera", "Antarctica/Rothera"),
  $KTS("antarctica_south_pole", "Antarctica/South_Pole"),
  $KTS("antarctica_syowa", "Antarctica/Syowa"),
  $KTS("antarctica_vostok", "Antarctica/Vostok"),
  $KTS("arctic_longyearbyen", "Arctic/Longyearbyen"),
  $KTS("asia_aden", "Asia/Aden"),
  $KTS("asia_almaty", "Asia/Almaty"),
  $KTS("asia_amman", "Asia/Amman"),
  $KTS("asia_anadyr", "Asia/Anadyr"),
  $KTS("asia_aqtau", "Asia/Aqtau"),
  $KTS("asia_aqtobe", "Asia/Aqtobe"),
  $KTS("asia_ashgabat", "Asia/Ashgabat"),
  $KTS("asia_baghdad", "Asia/Baghdad"),
  $KTS("asia_bahrain", "Asia/Bahrain"),
  $KTS("asia_baku", "Asia/Baku"),
  $KTS("asia_bangkok", "Asia/Bangkok"),
  $KTS("asia_beirut", "Asia/Beirut"),
  $KTS("asia_bishkek", "Asia/Bishkek"),
  $KTS("asia_brunei", "Asia/Brunei"),
  $KTS("asia_choibalsan", "Asia/Choibalsan"),
  $KTS("asia_chongqing", "Asia/Chongqing"),
  $KTS("asia_colombo", "Asia/Colombo"),
  $KTS("asia_damascus", "Asia/Damascus"),
  $KTS("asia_dhaka", "Asia/Dhaka"),
  $KTS("asia_dili", "Asia/Dili"),
  $KTS("asia_dubai", "Asia/Dubai"),
  $KTS("asia_dushanbe", "Asia/Dushanbe"),
  $KTS("asia_gaza", "Asia/Gaza"),
  $KTS("asia_harbin", "Asia/Harbin"),
  $KTS("asia_hebron", "Asia/Hebron"),
  $KTS("asia_ho_chi_minh", "Asia/Ho_Chi_Minh"),
  $KTS("asia_hong_kong", "Asia/Hong_Kong"),
  $KTS("asia_hovd", "Asia/Hovd"),
  $KTS("asia_irkutsk", "Asia/Irkutsk"),
  $KTS("asia_jakarta", "Asia/Jakarta"),
  $KTS("asia_jayapura", "Asia/Jayapura"),
  $KTS("asia_jerusalem", "Asia/Jerusalem"),
  $KTS("asia_kabul", "Asia/Kabul"),
  $KTS("asia_kamchatka", "Asia/Kamchatka"),
  $KTS("asia_karachi", "Asia/Karachi"),
  $KTS("asia_kashgar", "Asia/Kashgar"),
  $KTS("asia_kathmandu", "Asia/Kathmandu"),
  $KTS("asia_khandyga", "Asia/Khandyga"),
  $KTS("asia_kolkata", "Asia/Kolkata"),
  $KTS("asia_krasnoyarsk", "Asia/Krasnoyarsk"),
  $KTS("asia_kuala_lumpur", "Asia/Kuala_Lumpur"),
  $KTS("asia_kuching", "Asia/Kuching"),
  $KTS("asia_kuwait", "Asia/Kuwait"),
  $KTS("asia_macau", "Asia/Macau"),
  $KTS("asia_magadan", "Asia/Magadan"),
  $KTS("asia_makassar", "Asia/Makassar"),
  $KTS("asia_manila", "Asia/Manila"),
  $KTS("asia_muscat", "Asia/Muscat"),
  $KTS("asia_nicosia", "Asia/Nicosia"),
  $KTS("asia_novokuznetsk", "Asia/Novokuznetsk"),
  $KTS("asia_novosibirsk", "Asia/Novosibirsk"),
  $KTS("asia_omsk", "Asia/Omsk"),
  $KTS("asia_oral", "Asia/Oral"),
  $KTS("asia_phnom_penh", "Asia/Phnom_Penh"),
  $KTS("asia_pontianak", "Asia/Pontianak"),
  $KTS("asia_pyongyang", "Asia/Pyongyang"),
  $KTS("asia_qatar", "Asia/Qatar"),
  $KTS("asia_qyzylorda", "Asia/Qyzylorda"),
  $KTS("asia_rangoon", "Asia/Rangoon"),
  $KTS("asia_riyadh", "Asia/Riyadh"),
  $KTS("asia_sakhalin", "Asia/Sakhalin"),
  $KTS("asia_samarkand", "Asia/Samarkand"),
  $KTS("asia_seoul", "Asia/Seoul"),
  $KTS("asia_shanghai", "Asia/Shanghai"),
  $KTS("asia_singapore", "Asia/Singapore"),
  $KTS("asia_taipei", "Asia/Taipei"),
  $KTS("asia_tashkent", "Asia/Tashkent"),
  $KTS("asia_tbilisi", "Asia/Tbilisi"),
  $KTS("asia_tehran", "Asia/Tehran"),
  $KTS("asia_thimphu", "Asia/Thimphu"),
  $KTS("asia_tokyo", "Asia/Tokyo"),
  $KTS("asia_ulaanbaatar", "Asia/Ulaanbaatar"),
  $KTS("asia_urumqi", "Asia/Urumqi"),
  $KTS("asia_ust_nera", "Asia/Ust-Nera"),
  $KTS("asia_vientiane", "Asia/Vientiane"),
  $KTS("asia_vladivostok", "Asia/Vladivostok"),
  $KTS("asia_yakutsk", "Asia/Yakutsk"),
  $KTS("asia_yekaterinburg", "Asia/Yekaterinburg"),
  $KTS("asia_yerevan", "Asia/Yerevan"),
  $KTS("atlantic_azores", "Atlantic/Azores"),
  $KTS("atlantic_bermuda", "Atlantic/Bermuda"),
  $KTS("atlantic_canary", "Atlantic/Canary"),
  $KTS("atlantic_cape_verde", "Atlantic/Cape_Verde"),
  $KTS("atlantic_faroe", "Atlantic/Faroe"),
  $KTS("atlantic_madeira", "Atlantic/Madeira"),
  $KTS("atlantic_reykjavik", "Atlantic/Reykjavik"),
  $KTS("atlantic_south_georgia", "Atlantic/South_Georgia"),
  $KTS("atlantic_st_helena", "Atlantic/St_Helena"),
  $KTS("atlantic_stanley", "Atlantic/Stanley"),
  $KTS("australia_adelaide", "Australia/Adelaide"),
  $KTS("australia_brisbane", "Australia/Brisbane"),
  $KTS("australia_broken_hill", "Australia/Broken_Hill"),
  $KTS("australia_currie", "Australia/Currie"),
  $KTS("australia_darwin", "Australia/Darwin"),
  $KTS("australia_eucla", "Australia/Eucla"),
  $KTS("australia_hobart", "Australia/Hobart"),
  $KTS("australia_lindeman", "Australia/Lindeman"),
  $KTS("australia_lord_howe", "Australia/Lord_Howe"),
  $KTS("australia_melbourne", "Australia/Melbourne"),
  $KTS("australia_perth", "Australia/Perth"),
  $KTS("australia_sydney", "Australia/Sydney"),
  $KTS("canada_atlantic", "Canada/Atlantic"),
  $KTS("canada_central", "Canada/Central"),
  $KTS("canada_eastern", "Canada/Eastern"),
  $KTS("canada_mountain", "Canada/Mountain"),
  $KTS("canada_newfoundland", "Canada/Newfoundland"),
  $KTS("canada_pacific", "Canada/Pacific"),
  $KTS("europe_amsterdam", "Europe/Amsterdam"),
  $KTS("europe_andorra", "Europe/Andorra"),
  $KTS("europe_athens", "Europe/Athens"),
  $KTS("europe_belgrade", "Europe/Belgrade"),
  $KTS("europe_berlin", "Europe/Berlin"),
  $KTS("europe_bratislava", "Europe/Bratislava"),
  $KTS("europe_brussels", "Europe/Brussels"),
  $KTS("europe_bucharest", "Europe/Bucharest"),
  $KTS("europe_budapest", "Europe/Budapest"),
  $KTS("europe_busingen", "Europe/Busingen"),
  $KTS("europe_chisinau", "Europe/Chisinau"),
  $KTS("europe_copenhagen", "Europe/Copenhagen"),
  $KTS("europe_dublin", "Europe/Dublin"),
  $KTS("europe_gibraltar", "Europe/Gibraltar"),
  $KTS("europe_guernsey", "Europe/Guernsey"),
  $KTS("europe_helsinki", "Europe/Helsinki"),
  $KTS("europe_isle_of_man", "Europe/Isle_of_Man"),
  $KTS("europe_istanbul", "Europe/Istanbul"),
  $KTS("europe_jersey", "Europe/Jersey"),
  $KTS("europe_kaliningrad", "Europe/Kaliningrad"),
  $KTS("europe_kiev", "Europe/Kiev"),
  $KTS("europe_lisbon", "Europe/Lisbon"),
  $KTS("europe_ljubljana", "Europe/Ljubljana"),
  $KTS("europe_london", "Europe/London"),
  $KTS("europe_luxembourg", "Europe/Luxembourg"),
  $KTS("europe_madrid", "Europe/Madrid"),
  $KTS("europe_malta", "Europe/Malta"),
  $KTS("europe_mariehamn", "Europe/Mariehamn"),
  $KTS("europe_minsk", "Europe/Minsk"),
  $KTS("europe_monaco", "Europe/Monaco"),
  $KTS("europe_moscow", "Europe/Moscow"),
  $KTS("europe_oslo", "Europe/Oslo"),
  $KTS("europe_paris", "Europe/Paris"),
  $KTS("europe_podgorica", "Europe/Podgorica"),
  $KTS("europe_prague", "Europe/Prague"),
  $KTS("europe_riga", "Europe/Riga"),
  $KTS("europe_rome", "Europe/Rome"),
  $KTS("europe_samara", "Europe/Samara"),
  $KTS("europe_san_marino", "Europe/San_Marino"),
  $KTS("europe_sarajevo", "Europe/Sarajevo"),
  $KTS("europe_simferopol", "Europe/Simferopol"),
  $KTS("europe_skopje", "Europe/Skopje"),
  $KTS("europe_sofia", "Europe/Sofia"),
  $KTS("europe_stockholm", "Europe/Stockholm"),
  $KTS("europe_tallinn", "Europe/Tallinn"),
  $KTS("europe_tirane", "Europe/Tirane"),
  $KTS("europe_uzhgorod", "Europe/Uzhgorod"),
  $KTS("europe_vaduz", "Europe/Vaduz"),
  $KTS("europe_vatican", "Europe/Vatican"),
  $KTS("europe_vienna", "Europe/Vienna"),
  $KTS("europe_vilnius", "Europe/Vilnius"),
  $KTS("europe_volgograd", "Europe/Volgograd"),
  $KTS("europe_warsaw", "Europe/Warsaw"),
  $KTS("europe_zagreb", "Europe/Zagreb"),
  $KTS("europe_zaporozhye", "Europe/Zaporozhye"),
  $KTS("europe_zurich", "Europe/Zurich"),
  $KTS("gmt", "GMT"),
  $KTS("indian_antananarivo", "Indian/Antananarivo"),
  $KTS("indian_chagos", "Indian/Chagos"),
  $KTS("indian_christmas", "Indian/Christmas"),
  $KTS("indian_cocos", "Indian/Cocos"),
  $KTS("indian_comoro", "Indian/Comoro"),
  $KTS("indian_kerguelen", "Indian/Kerguelen"),
  $KTS("indian_mahe", "Indian/Mahe"),
  $KTS("indian_maldives", "Indian/Maldives"),
  $KTS("indian_mauritius", "Indian/Mauritius"),
  $KTS("indian_mayotte", "Indian/Mayotte"),
  $KTS("indian_reunion", "Indian/Reunion"),
  $KTS("pacific_apia", "Pacific/Apia"),
  $KTS("pacific_auckland", "Pacific/Auckland"),
  $KTS("pacific_chatham", "Pacific/Chatham"),
  $KTS("pacific_chuuk", "Pacific/Chuuk"),
  $KTS("pacific_easter", "Pacific/Easter"),
  $KTS("pacific_efate", "Pacific/Efate"),
  $KTS("pacific_enderbury", "Pacific/Enderbury"),
  $KTS("pacific_fakaofo", "Pacific/Fakaofo"),
  $KTS("pacific_fiji", "Pacific/Fiji"),
  $KTS("pacific_funafuti", "Pacific/Funafuti"),
  $KTS("pacific_galapagos", "Pacific/Galapagos"),
  $KTS("pacific_gambier", "Pacific/Gambier"),
  $KTS("pacific_guadalcanal", "Pacific/Guadalcanal"),
  $KTS("pacific_guam", "Pacific/Guam"),
  $KTS("pacific_honolulu", "Pacific/Honolulu"),
  $KTS("pacific_johnston", "Pacific/Johnston"),
  $KTS("pacific_kiritimati", "Pacific/Kiritimati"),
  $KTS("pacific_kosrae", "Pacific/Kosrae"),
  $KTS("pacific_kwajalein", "Pacific/Kwajalein"),
  $KTS("pacific_majuro", "Pacific/Majuro"),
  $KTS("pacific_marquesas", "Pacific/Marquesas"),
  $KTS("pacific_midway", "Pacific/Midway"),
  $KTS("pacific_nauru", "Pacific/Nauru"),
  $KTS("pacific_niue", "Pacific/Niue"),
  $KTS("pacific_norfolk", "Pacific/Norfolk"),
  $KTS("pacific_noumea", "Pacific/Noumea"),
  $KTS("pacific_pago_pago", "Pacific/Pago_Pago"),
  $KTS("pacific_palau", "Pacific/Palau"),
  $KTS("pacific_pitcairn", "Pacific/Pitcairn"),
  $KTS("pacific_pohnpei", "Pacific/Pohnpei"),
  $KTS("pacific_port_moresby", "Pacific/Port_Moresby"),
  $KTS("pacific_rarotonga", "Pacific/Rarotonga"),
  $KTS("pacific_saipan", "Pacific/Saipan"),
  $KTS("pacific_tahiti", "Pacific/Tahiti"),
  $KTS("pacific_tarawa", "Pacific/Tarawa"),
  $KTS("pacific_tongatapu", "Pacific/Tongatapu"),
  $KTS("pacific_wake", "Pacific/Wake"),
  $KTS("pacific_wallis", "Pacific/Wallis"),
  $KTS("us_alaska", "US/Alaska"),
  $KTS("us_arizona", "US/Arizona"),
  $KTS("us_central", "US/Central"),
  $KTS("us_eastern", "US/Eastern"),
  $KTS("us_hawaii", "US/Hawaii"),
  $KTS("us_mountain", "US/Mountain"),
  $KTS("us_pacific", "US/Pacific"),
  $KTS("utc", "UTC"),
];

export enum CloudStatus {
  SAVED = "saved",
  SAVING = "saving",
  SAVE_FAILED = "save_failed",
}

export type CloudStatusMetadata = {
  type: CloudStatus,
  text: string,
  iconType: typeof SvgIcon,
}

export const CLOUD_STATUS_METADATA: CloudStatusMetadata[] = [
  {
    type: CloudStatus.SAVED,
    text: "Saved to cloud",
    iconType: CloudDoneOutlined,
  },
  {
    type: CloudStatus.SAVING,
    text: "Saving...",
    iconType: SyncOutlined,
  },
  {
    type: CloudStatus.SAVE_FAILED,
    text: "Failed to save to cloud",
    iconType: WarningOutlined,
  },
];

// console.log(i.map(v => "$KTS(\"" + v.toLowerCase().replaceAll(/[^a-z]/g, "_") + "\", \"" + v + "\")").join(",\n"));
