import {colorYellow} from "../shared/colors";

// This is staging
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyD4PW-EkfMYF5t-Uq7vft3jreIGksPzCOs",
  authDomain: "plugins-playground-7971a.firebaseapp.com",
  projectId: "plugins-playground-7971a",
  storageBucket: "plugins-playground-7971a.appspot.com",
  messagingSenderId: "66818322724",
  appId: "1:66818322724:web:c03ec9054d26ffb40e83b1",
  measurementId: "G-H0VZ9TTFMY"
};

// This is prod
// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyDDIBEaYRx_5lSb0_fOVRXnP9wV3e1SBEA",
//   authDomain: "fyneapps-apps.firebaseapp.com",
//   databaseURL: "https://fyneapps-apps-default-rtdb.firebaseio.com",
//   projectId: "fyneapps-apps",
//   storageBucket: "fyneapps-apps.appspot.com",
//   messagingSenderId: "260329543886",
//   appId: "1:260329543886:web:c36b2d04e9233fb3ccd89f",
//   measurementId: "G-NNJ9VRX024",
// }

export const THEME_COLOR_PRIMARY = "#00272d";
export const THEME_COLOR_PRIMARY_CONTRAST = "#fff";
export const THEME_COLOR_SECONDARY = colorYellow;
export const THEME_COLOR_SECONDARY_CONTRAST = "#000";
