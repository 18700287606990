export abstract class BaseKeyText<T> {

  constructor(readonly key: T, readonly text: string) {
  }
}

export class KeyText extends BaseKeyText<any> {

  constructor(key: any, text: string) {
    super(key, text);
  }
}

export class KeyTextNumber extends BaseKeyText<Number> {

  constructor(key: number, text: string) {
    super(key, text);
  }
}

export class KeyTextString extends BaseKeyText<string> {

  constructor(key: string, text: string) {
    super(key, text);
  }
}

export function $KT(key: any, text: string) {
  return new KeyText(key, text);
}

export function $KTS(key: string, text: string) {
  return new KeyTextString(key, text);
}

export function $F(formatString: string, ...keyTexts: KeyText[]): string {
  let formatted = formatString;
  for (const keyText of keyTexts) {
    formatted = formatted.replaceAll("%{" + keyText.key + "}", keyText.text);
  }
  return formatted;
}
