import {BaseFragment, BaseFragmentProps} from "./BaseFragment";
import {EmptyConfig} from "./types";
import {ListAltOutlined} from "@mui/icons-material";
import {ReactElement} from "react";

export type UnselectedFragmentProps = BaseFragmentProps & {
  config?: EmptyConfig,
}

export class UnselectedFragment extends BaseFragment<UnselectedFragmentProps> {

  protected getUnselectedConfig(): EmptyConfig {
    return this.props.config || {
      iconType: ListAltOutlined,
      title: "Nothing selected",
      text: "Please make a selection",
    };
  }

  render(): ReactElement {
    return this.renderUnselected();
  }
}
