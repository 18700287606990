import {BaseFragment, BaseFragmentProps} from "./BaseFragment";
import {PureComponent, ReactElement} from "react";
import {JSON_OBJECT} from "./json/helpers";
import {Observable, PluginConfig} from "./types";
import {StyledVisible} from "./StyledComponents";

export type PluginHostProps = BaseFragmentProps & {
  hostId: string,
  disablePluginsCache?: boolean,
}

function renderPluginComponent(url: string, config: PluginConfig) {
  const encodedConfig = btoa(JSON.stringify(JSON_OBJECT.serializeObject(config)));
  // Make sure "Block third-party cookies (in incognito or otherwise)" in chrome://settings/content is disabled for
  // urls loaded here (and "localhost"), otherwise a localStorage error will be thrown.
  return <iframe style={{flexGrow: 1}} frameBorder="0"
                 src={url + "?pcfg=" + encodedConfig}/>;
}

class ComponentsCache {

  private readonly components = new Map<string, ReactElement>();

  render(url: string, config: PluginConfig): ReactElement {
    if (url && config) {
      let component = this.components.get(url);
      if (!component) {
        component = renderPluginComponent(url, config);
        this.components.set(url, component);
      }
    }
    return <>
      {[...this.components.entries()].map(([componentUrl, component]) =>
        <StyledVisible visible={componentUrl === url} style={{flexGrow: 1}}>
          {component}
        </StyledVisible>
      )}
    </>;
  }
}

export interface PluginHostListener {

  onPluginChanged(host: PluginHost);
}

export class PluginHost extends Observable<PluginHostListener> {

  private static readonly instances = new Map<string, PluginHost>();

  private url: string;
  private config: PluginConfig;

  static getInstance(hostId: string) {
    let host = this.instances.get(hostId);
    if (!host) {
      host = new PluginHost();
      this.instances.set(hostId, host);
    }
    return host;
  }

  private constructor() {
    super();
  }

  getUrl(): string {
    return this.url;
  }

  getConfig(): PluginConfig {
    return this.config;
  }

  set(url: string, config: PluginConfig) {
    this.url = url;
    this.config = config;
    this.observers.forEach(observer => observer.onPluginChanged(this));
  }
}

export class PluginHostFragment extends BaseFragment<PluginHostProps> implements PluginHostListener {

  private static readonly componentMap = new Map<string, ComponentsCache>();

  constructor(props: PluginHostProps, context: any) {
    super(props, context);
    PluginHost.getInstance(this.props.hostId).registerObserver(this);
  }

  protected renderContainerContent(): ReactElement | null {
    const host = PluginHost.getInstance(this.props.hostId);
    let rendered = null;
    if (!this.props.disablePluginsCache) {
      let cache = PluginHostFragment.componentMap.get(this.props.hostId);
      if (!cache) {
        cache = new ComponentsCache();
        PluginHostFragment.componentMap.set(this.props.hostId, cache);
      }
      rendered = cache.render(host.getUrl(), host.getConfig());
    } else if (host.getUrl() && host.getConfig()) {
      rendered = renderPluginComponent(host.getUrl(), host.getConfig());
    }
    return rendered;
  }

  onPluginChanged(host: PluginHost) {
    this.forceUpdate();
  }
}

export type PluginFragmentProps = {
  hostId: string,
  url: string,
  config: PluginConfig,
}

export class PluginFragment extends PureComponent<PluginFragmentProps> {

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps: Readonly<PluginFragmentProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.hostId !== this.props.hostId || prevProps.url !== this.props.url || prevProps.config !== this.props.config) {
      this.update();
    }
  }

  private update() {
    PluginHost.getInstance(this.props.hostId).set(this.props.url, this.props.config);
  }

  componentWillUnmount() {
    PluginHost.getInstance(this.props.hostId).set(null, null);
  }

  render() {
    return null;
  }
}
