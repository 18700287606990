import {Notifications} from "./types";
import {PageFragment, PageFragmentProps, PageFragmentState} from "./PageFragment";
import {TabInfo} from "./TabsContainer";
import React from "react";
import {PageWithSidebarContainer} from "./PageWithSidebarContainer";
import {SidebarItem, SidebarTab} from "./Sidebar";
import {UnselectedFragment} from "./UnselectedFragment";
import {PlaceholderFragment} from "./PlaceholderFragment";
import {PathProps} from "../index";
import {NotificationsOutlined} from "@mui/icons-material";
import {BaseApp} from "./BaseApp";

enum NotificationsTabType {
  ALL = "all",
  UNREAD = "unread",
}

const TABS: TabInfo<NotificationsTabType>[] = [
  {
    type: NotificationsTabType.ALL,
    text: "All",
  },
  {
    type: NotificationsTabType.UNREAD,
    text: "Unread",
  },
];

export type NotificationsFragmentProps = PageFragmentProps & {}

type NotificationsFragmentState = PageFragmentState & {}

export class NotificationsFragment extends PageFragment<NotificationsFragmentProps, NotificationsFragmentState> {

  private static TABS: SidebarTab[] = [
    {
      id: NotificationsTabType.ALL,
      title: "All",
      items: {
        groups: [
          {
            items: [
              {
                path: "-",
                render: () => <UnselectedFragment/>,
              },
              {
                path: ":notif_id",
                render: (pathProps: PathProps) => <PlaceholderFragment path={pathProps}/>,
              },
            ],
            _asyncInit: async (items: SidebarItem[]) => {
              return (await Notifications.getInstance().getOrLoadListItems()).map(notif => ({
                path: notif.id,
                title: notif.title,
                text: notif.text,
                iconUrl: notif.iconUrl,
                iconType: NotificationsOutlined,
                iconBackgroundColor: BaseApp.CONTEXT.getAppConfig().theme?.palette.primary.main,
              }));
            },
          },
        ],
        containerId: "notifications",
      },
    },
    {
      id: NotificationsTabType.UNREAD,
      title: "Unread",
      items: {
        groups: [
          {
            items: [
              {
                path: "unread/:notif_id",
                render: (pathProps: PathProps) => <PlaceholderFragment path={pathProps}/>,
              },
            ],
            _asyncInit: async (items: SidebarItem[]) => {
              return (await Notifications.getInstance().getOrLoadListItems()).filter(notif => !Boolean(notif.readAt)).map(notif => ({
                path: notif.id,
                title: notif.title,
                text: notif.text,
                iconUrl: notif.iconUrl,
                iconType: NotificationsOutlined,
                iconBackgroundColor: BaseApp.CONTEXT.getAppConfig().theme?.palette.primary.main,
              }));
            },
          },
        ],
        containerId: "notifications",
      },
    },
  ];

  private readonly loader = Notifications.getInstance();

  renderContent(): React.ReactElement {
    return <PageWithSidebarContainer
      path={this.props.path}
      sidebarTabs={NotificationsFragment.TABS}/>
  }
}