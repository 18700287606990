import React, {ReactElement} from "react";
import {Box, CircularProgress} from "@mui/material";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../../shared/BaseFragment";

export interface WidgetContainer {
  minimizePressed();
}

export type BaseWidgetProps<CONFIG> = BaseFragmentProps & {
  container: WidgetContainer,
  config?: CONFIG,
}

export type BaseWidgetState<CONTENT> = BaseFragmentState & {
  content?: CONTENT,
}

export type WidgetStyleFlags = {
  immersive?: boolean,
}

export abstract class BaseWidget<CONFIG = {}, CONTENT = {}, P extends BaseWidgetProps<CONFIG> = BaseWidgetProps<CONFIG>, S extends BaseWidgetState<CONTENT> = BaseWidgetState<CONTENT>> extends BaseFragment<P, S> {

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
    if (prevProps.config !== this.props.config) {
      this.reload(true);
    }
  }

  protected async fetchOnMount() {
    const content = await this.onLoadContent();
    this.setState({
      content: content,
    });
  }

  protected async onLoadContent(): Promise<CONTENT> {
    return null;
  }

  protected styleFlags(): WidgetStyleFlags {
    return {};
  }

  componentWillUnmount() {
  }

  protected renderContainerContent(): React.ReactElement | null {
    return this.renderContent(this.state.content);
  }

  protected renderContent(content?: CONTENT): ReactElement {
    return null;
  }

  protected renderLoading() {
    return <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 24,
        flexGrow: 1,
      }}>
      <CircularProgress/>
    </Box>;
  }
}