import {JSON_OBJECT, Type} from "./json/helpers";

export function itemToJSONArray(array: { toJSON(): any }[]): any[] {
  if (!array) {
    return [];
  }
  return array.map(item => item.toJSON());
}

export function jsonToItemArray<T>(klass: { fromJSON(json: any): T }, array: any[]): T[] {
  if (!array) {
    return [];
  }
  return array.map(item => klass.fromJSON(item));
}

export function cloneObject<T extends object>(object: T, dataType: Type<T>): T {
  return JSON_OBJECT.deserializeObject(JSON_OBJECT.serializeObject(object), dataType) as T;
}

export function deleteUnusedFields(object: any): any {
  Object.keys(object).forEach(key => {
    const value = object[key];
    if (value === undefined || value === null) {
      delete object[key];
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      deleteUnusedFields(value)
    }
  });
  return object;
}
