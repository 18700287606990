import {QuoteResponse} from "./types";
import {getCloudHost} from "../consts";

export class Api {

  private static instance;

  static getInstance(): Api {
    if (!this.instance) {
      this.instance = new Api();
    }
    return this.instance;
  }

  private constructor() {
  }

  private static readonly URL = getCloudHost() + "/apps/quotes";

  async quote(): Promise<QuoteResponse> {
    const json = await fetch(Api.URL).then(resp => resp.json());
    return json as QuoteResponse;
  }
}
