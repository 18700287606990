export class Prefs {

  private readonly prefix: string;

  constructor(prefix: string = "") {
    this.prefix = prefix?.length > 0 ? prefix + "-" : "";
  }

  clear() {
    localStorage.clear();
  }

  remove(key: string) {
    return localStorage.removeItem(key);
  }

  getBoolean(key: string): boolean {
    let item = localStorage.getItem(this.prefix + key);
    return item && item === "true";
  }

  setBoolean(key: string, value: boolean) {
    localStorage.setItem(this.prefix + key, "" + value);
  }

  getNumber(key: string, default_?: number): number {
    let item = localStorage.getItem(this.prefix + key);
    return item ? Number.parseInt(item) : default_;
  }

  setNumber(key: string, value: number) {
    localStorage.setItem(this.prefix + key, "" + value);
  }

  getString(key: string, default_: string = null): string {
    let item = localStorage.getItem(this.prefix + key);
    return item || default_;
  }

  setString(key: string, value: string) {
    localStorage.setItem(this.prefix + key, value);
  }
}
