import React, {ReactElement} from "react";
import {BaseWidget} from "../BaseWidget";
import {Api} from "./api";
import {Clock, ClockGranularity, ClockListener} from "../../../shared/clock";
import {QuoteResponse} from "./types";
import {Typography} from "@mui/material";
import {PD_XLG} from "../../../shared/dimens";
import {StyledBoxColumn} from "../../../shared/StyledComponents";

const COLORS = [
  "#fffafa",
  "#f8f9f5",
  "#f0fff0",
  "#fffacd",
  "#EEEEFF",
  "#ebf6f7",
  "#f3f0e8",
  "#eaf4fc",
  "#f1f9ec",
  "#f3f2ed",
  "#ffe4e1",
];

export type QuotesContent = {
  text: string,
  author: string,
}

export class QuotesWidget extends BaseWidget<{}, QuotesContent> implements ClockListener {

  private readonly api = Api.getInstance();
  private readonly clock = Clock.getInstance();
  private readonly background = COLORS[Math.floor(Math.random() * COLORS.length)];

  componentDidMount() {
    super.componentDidMount();
    this.clock.addListener(ClockGranularity.MINUTE, this);
  }

  componentWillUnmount() {
    this.clock.removeListener(ClockGranularity.MINUTE, this);
    super.componentWillUnmount();
  }

  tick(value: number) {
    this.reload();
  }

  protected async onLoadContent(): Promise<QuotesContent> {
    let tries = 0;
    let quoteText: string;
    let quote: QuoteResponse;
    do {
      quote = await this.api.quote();
      tries++;
    } while (tries < 5 && (quoteText = quote?.quoteText)?.length >= 120);
    return {
      text: quoteText,
      author: quote.quoteAuthor?.trim(),
    };
  }

  protected renderContent(content: QuotesContent): ReactElement {
    return <StyledBoxColumn style={{
      padding: PD_XLG,
    }}>
      <img style={{width: "100%"}} src={"/images/widgets/quotes/divider0.png"}/>
      <Typography style={{fontSize: "125%", lineHeight: 1.15, fontWeight: "bold"}}>{content.text}</Typography>
      <Typography>{content.author}</Typography>
    </StyledBoxColumn>;
  }
}