import {PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import React from "react";
import {Card, Fade, Grid, IconButton, Typography} from "@mui/material";
import {BaseFragment, BaseFragmentProps} from "../../shared/BaseFragment";
import {SystemWidgets, WidgetSpec} from "./types";
import {StyledBoxColumn, StyledBoxRow, StyledContainer} from "../../shared/StyledComponents";
import {BORDER_RADIUS, BORDER_RADIUS_LARGE, DIVIDER, PD_LG, PD_MD} from "../../shared/dimens";
import {MoreVertOutlined} from "@mui/icons-material";
import {lightGray} from "../../shared/colors";
import {WidgetContainer} from "../widgets/BaseWidget";

export type WidgetViewProps = BaseFragmentProps & {
  spec: WidgetSpec,
}

class WidgetView extends BaseFragment<WidgetViewProps> implements WidgetContainer {
  minimizePressed() {
  }

  protected renderContainerContent(): React.ReactElement | null {
    const spec = this.props.spec;
    const Widget = SystemWidgets.getInstance().get(spec.customRendererId);
    return <Fade in timeout={{enter: 200 + 800 * Math.random()}}>
      <Card style={{border: DIVIDER, borderRadius: BORDER_RADIUS_LARGE}}>
      <StyledBoxColumn style={{paddingBottom: 0, gap:0}}>
        <StyledBoxRow style={{padding: PD_LG, alignItems: "center", background: lightGray}}>
          <img style={{width: 48, height: 48, borderRadius: BORDER_RADIUS}} src={spec.icon}/>
          <Typography variant="h6" style={{flexGrow: 1}}>{spec.title}</Typography>
          <IconButton>
            <MoreVertOutlined/>
          </IconButton>
        </StyledBoxRow>
        {Widget ? <Widget container={this}/> : null}
      </StyledBoxColumn>
    </Card>
    </Fade>;
  }
}

export type WidgetsFragmentProps = PageFragmentProps & {}

type WidgetsFragmentState = PageFragmentState & {
  specs: WidgetSpec[],
}

export class WidgetsFragment extends BaseFragment<WidgetsFragmentProps, WidgetsFragmentState> {

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const weather = new WidgetSpec("weather", null, null);
    weather.customRendererId = "weather";
    weather.title = "Weather";
    weather.icon = "/images/widgets/weather/icon.png";
    const clock = new WidgetSpec("clock", null, null);
    clock.customRendererId = "clock";
    clock.title = "Clock";
    clock.icon = "/images/widgets/clock/icon.png";
    const quotes = new WidgetSpec("quotes", null, null);
    quotes.customRendererId = "quotes";
    quotes.title = "Quotes";
    quotes.icon = "/images/widgets/quotes/icon.png";
    const jokes = new WidgetSpec("jokes", null, null);
    jokes.customRendererId = "jokes";
    jokes.title = "Jokes";
    jokes.icon = "/images/widgets/jokes/icon.png";
    this.setState({
      specs: [weather, clock, quotes, jokes],
    });
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <StyledContainer size={"xxlg"} style={{padding: PD_MD, flexGrow: 1}}>
      <Grid container spacing={2}>
        {this.state.specs.map(spec => <Grid item xs={12} sm={6} md={4}>
          <WidgetView spec={spec}/>
        </Grid>)}
      </Grid>
    </StyledContainer>;
  }
}