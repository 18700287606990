import {BaseFragment, BaseFragmentProps} from "./BaseFragment";
import {EmptyConfig} from "./types";
import {CampaignOutlined} from "@mui/icons-material";
import {ReactElement} from "react";

export type PlaceholderFragmentProps = BaseFragmentProps & {}

export class PlaceholderFragment extends BaseFragment<PlaceholderFragmentProps> {

  protected getEmptyConfig(): EmptyConfig {
    return {
      iconType: CampaignOutlined,
      title: "Coming soon",
      text: "This section is under construction. Please check back again later.",
    };
  }

  render(): ReactElement {
    return this.renderEmpty();
  }
}
