import React, {ReactElement} from "react";
import {NotificationsOutlined,} from "@mui/icons-material";
import {TabInfo, Tabs} from "shared/TabsContainer";
import {AppTabsContainer, AppTabsContainerProps, AppTabsContainerState} from "shared/AppTabsContainer";
import {PathComponent, PathProps} from "index";
import {Box, Button, ButtonBase, TextField, Typography, useTheme} from "@mui/material";
import {BORDER_RADIUS, DIVIDER, PAGE_FRAGMENT_HALF_WIDTH, PD_LG, PD_SM, PD_XXSM, SZ_MD, TAB_SIZE} from "shared/dimens";
import {StyledBadge, StyledBoxRow, StyledSpan} from "shared/StyledComponents";
import {BaseApp, getProvisioningId} from "./BaseApp";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "shared/BaseFragment";
import {PluginConfigProvider, Plugins} from "shared/plugins";
import {CloudStatus} from "shared/constants";
import {PluginConfig} from "./types";
import {DirectoryOrgUnitRoot} from "./directory/types";
import {ProvisioningContext, SystemProvisioningIds} from "./database";
import {findIcon} from "./icons";
import {NotificationsFragment} from "./NotificationsFragment";

type MainToolbarProps = {
  path?: PathProps,
  title: string,
  headerToolbar: ReactElement,
  titleToolbar: ReactElement,
  orgUnitRoot?: DirectoryOrgUnitRoot,
}

function MainToolbar(props: MainToolbarProps): React.ReactElement {
  const searchConfig = BaseApp.CONTEXT.getAppConfig()?.searchConfig;
  return <Box style={{display: "flex", flexGrow: 1, flexDirection: "column", height: 72}}>
    {props.headerToolbar}
    <Box style={{
      gap: PD_SM,
      display: "flex",
      paddingLeft: PD_SM,
      paddingRight: PD_SM,
      height: props.headerToolbar ? 40 : 72,
      alignItems: "center",
      position: "relative",
    }}>
      {props.orgUnitRoot
        ? <div style={{width: 128}}>
          <img src={props.orgUnitRoot.logo}
               style={{width: 128, height: 32, objectFit: "contain"}}/>
        </div>
        : null}
      <Typography variant="h5" style={{
        paddingLeft: PD_SM,
        paddingRight: PD_SM,
        paddingTop: PD_XXSM,
        paddingBottom: PD_XXSM,
        maxWidth: 360,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
      }}>{props.title}</Typography>
      <StyledSpan/>
      {searchConfig
        ? <TextField
          size={"small"}
          inputProps={{style: {padding: '4px 12px'}}}
          style={{minWidth: PAGE_FRAGMENT_HALF_WIDTH}}
          placeholder={"Search (⌘+K)"}/>
        : null}
      {props.titleToolbar}
      <StyledBoxRow style={{marginLeft: PD_XXSM}}>
        <StyledBadge badgeContent={1}>
          <Button onClick={() => props.path.navigate("/notifications")}>
            <NotificationsOutlined/>
          </Button>
        </StyledBadge>
      </StyledBoxRow>
    </Box>
  </Box>;
}

type MainContainerProps = MainToolbarProps & {
  logo: string,
  noChrome?: boolean,
  renderContent: () => ReactElement,
}

type MainContainerState = {}

function MainButton(props: { children: any }): ReactElement {
  const theme = useTheme();
  return <Box style={{
    width: TAB_SIZE + PD_LG * 2,
    height: TAB_SIZE,
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main
  }}>
    <ButtonBase
      onClick={() => this.props.path.navigate("/")}>
      {props.children}
    </ButtonBase>
  </Box>
}

class MainContainer extends React.Component<MainContainerProps, MainContainerState> {

  render() {
    return <Box style={{display: "flex", flexDirection: "column", width: "100vw", height: "100vh"}}>
      {!this.props.noChrome ? this.renderToolbarInContainer() : null}
      {this.props.renderContent()}
    </Box>
  }

  private renderToolbarInContainer() {
    return <Box style={{
      width: "100%",
      height: 72,
      display: "flex",
      flexDirection: "row",
      flexShrink: 0,
      borderBottom: DIVIDER,
    }}>
      <MainButton>
        {this.renderLogo()}
      </MainButton>
      <MainToolbar {...this.props}/>
    </Box>
  }

  private renderLogo(): ReactElement {
    const appConfig = BaseApp.CONTEXT.getAppConfig();
    const logo = this.props.logo || appConfig.logo || "@icon/extension";
    const theme = appConfig.theme;
    if (logo.endsWith(".png")) {
      return <img src={logo} style={{width: SZ_MD, height: SZ_MD}}/>;
    } else if (logo.startsWith("@icon/")) {
      const IconType = findIcon(logo.substring("@icon/".length));
      return <IconType style={{
        width: SZ_MD,
        height: SZ_MD,
        alignSelf: "center",
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.palette.primary.main,
        color: "white"
      }} viewBox="-6 -6 36 36"/>
    }
    return null;
  }
}

const ABSTRACT_TAB_ITEMS: TabInfo<string>[] = [
  {
    type: "plugins",
    path: ":plugin_id",
    groupType: "plugins",
    plugins: [],
  },
];

type MainTabsContainerProps = AppTabsContainerProps<string> & MainToolbarProps & {}

type MainTabsContainerState = AppTabsContainerState<string> & {
  cloudStatus: CloudStatus,
}

class MainTabsContainer extends AppTabsContainer<string, MainTabsContainerProps, MainTabsContainerState> {

  protected renderToolbar(): React.ReactElement {
    return <MainToolbar {...this.props}/>;
  }
}

export type AbstractMainProps = BaseFragmentProps & {
  noChrome?: boolean,
}

type AbstractMainState = BaseFragmentState & {
  orgUnitRoot?: DirectoryOrgUnitRoot,
}

export abstract class AbstractMain extends BaseFragment<AbstractMainProps, AbstractMainState> {

  static nestedPaths(): PathComponent[] {
    return [
      {
        path: "notifications",
        render: pathProps => <NotificationsFragment path={pathProps}/>,
      },
    ];
  }

  constructor(props: AbstractMainProps, context: any) {
    super(props, context);
    this.state = {}
  }

  protected async fetchOnMount(): Promise<void> {
    const provisioningId = getProvisioningId();
    if (provisioningId && provisioningId !== SystemProvisioningIds.MAIN) {
      this.setState({
        orgUnitRoot: await DirectoryOrgUnitRoot.loadWithProvisioningContext(new ProvisioningContext(provisioningId)),
      });
    }
  }

  protected headerToolbar: ReactElement;

  protected createHeaderToolbar(): ReactElement | null {
    return null;
  }

  protected titleToolbar: ReactElement;

  protected createTitleToolbar(): ReactElement {
    return null;
  }

  protected renderContainerContent(): ReactElement {
    if (this.headerToolbar === undefined) {
      this.headerToolbar = this.createHeaderToolbar();
    }
    if (this.titleToolbar === undefined) {
      this.titleToolbar = this.createTitleToolbar();
    }
    return this.renderMainContainer();
  }

  protected renderMainContainer(): ReactElement {
    const appConfig = BaseApp.CONTEXT.getAppConfig();
    return <MainContainer
      noChrome={this.props.noChrome}
      orgUnitRoot={this.state.orgUnitRoot}
      headerToolbar={this.headerToolbar}
      titleToolbar={this.titleToolbar}
      title={appConfig.name}
      logo={appConfig.logo}
      path={this.props.path}
      renderContent={() => this.renderMainContent()}
    />;
  }

  protected renderMainContent(): ReactElement {
    return null;
  }
}

export abstract class AbstractTabsMain extends AbstractMain implements PluginConfigProvider {

  private readonly plugins = Plugins.initInstance(BaseApp.CONTEXT.getAppPrefs().getPluginUrls(), this);

  constructor(props: AbstractMainProps, context: any) {
    super(props, context);
    this.state = {}
  }

  private static tabs(): Tabs<string> {
    return {
      items: [
        ...this.appTabs(),
        ...ABSTRACT_TAB_ITEMS,
      ],
      containerId: "main",
    };
  }

  static appTabs(): TabInfo<string>[] {
    return [];
  }

  static nestedPaths(): PathComponent[] {
    return [
      ...MainTabsContainer.nestedPathsFromTabs(this.tabs()),
      ...super.nestedPaths(),
    ];
  }

  abstract getPluginConfig(): PluginConfig;

  private _tabs: Tabs<string>;

  protected async fetchOnMount(): Promise<void> {
    const provisioningId = getProvisioningId();
    if (provisioningId && provisioningId !== SystemProvisioningIds.MAIN) {
      this.setState({
        orgUnitRoot: await DirectoryOrgUnitRoot.loadWithProvisioningContext(new ProvisioningContext(provisioningId)),
      });
    }
    //@ts-ignore
    this._tabs = this.constructor.tabs();
    const tabInfo: TabInfo<string> = this._tabs.items.find(item => item.type === "plugins");
    tabInfo.plugins = await this.plugins.getOrLoadPlugins();
  }

  protected renderMainContainer(): React.ReactElement {
    const appConfig = BaseApp.CONTEXT.getAppConfig();
    return <MainTabsContainer
      orgUnitRoot={this.state.orgUnitRoot}
      headerToolbar={this.headerToolbar}
      titleToolbar={this.titleToolbar}
      title={appConfig.name}
      logo={appConfig.logo}
      path={this.props.path}
      tabs={this._tabs}
    />;
  }
}
