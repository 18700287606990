import React, {ChangeEventHandler, Component, ReactElement, RefObject} from "react";
import {Box, CircularProgress, Fab, Typography} from "@mui/material";
import {Action, EmptyConfig} from "shared/types";
import {StyledButtonMore, StyledEmpty, StyledUnselected} from "./StyledComponents";
import {BaseApp} from "./BaseApp";
import {PathProps} from "../index";
import {PD_SM, SZ_LG} from "./dimens";

export interface InputSpec {
  ref: RefObject<HTMLInputElement>,
  type: "file" | string,
  multiple?: boolean,
  accept?: string,
  onChange: ChangeEventHandler<HTMLInputElement>,
}

export function createInput(inputSpec: InputSpec): ReactElement | null {
  if (!inputSpec) {
    return null;
  }
  return <input type={inputSpec.type} ref={inputSpec.ref}
                multiple={Boolean(inputSpec.multiple)} accept={inputSpec.accept || "*"}
                style={{display: "none"}}
                onChange={event => inputSpec.onChange(event)}/>;
}

export type BaseFragmentProps = {
  path?: PathProps,
  style?: any,
}

export interface BaseFragmentState {
  ready?: boolean,
  didRenderContainerContent?: boolean,
}

export const BASE_STATE = {};

export abstract class BaseFragment<P extends BaseFragmentProps = BaseFragmentProps, S extends BaseFragmentState = BaseFragmentState> extends Component<P, S> {

  protected readonly theme = BaseApp.CONTEXT.getAppConfig().theme;

  constructor(props: P, context: any) {
    super(props, context);
    this.state = this.onCreateState();
  }

  protected onCreateState(): S {
    return BASE_STATE as S;
  }

  private reloadedOnMount: boolean;

  componentDidMount() {
    if (this.reloadedOnMount) {
      return;
    }
    this.reloadedOnMount = true;
    this.reload();
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
    if (!prevState.didRenderContainerContent && this.state.didRenderContainerContent) {
      this.containerContentDidRender();
    }
  }

  protected reload(forceReload?: boolean) {
    this.setState({
      ready: false,
      didRenderContainerContent: false,
    });
    const fetchOnMount = this.fetchOnMount(forceReload);
    fetchOnMount.then(() => this.setState({
      ready: true,
    }));
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    return Promise.resolve();
  }

  render() {
    if (!this.state.ready) {
      return <Box style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <CircularProgress/>
      </Box>;
    }
    const rendered = this.renderContainerContent();
    if (!this.state.didRenderContainerContent) {
      this.setState({
        didRenderContainerContent: true,
      });
    }
    return rendered;
  }

  protected containerContentDidRender() {
  }

  protected renderContainerContent(): ReactElement | null {
    return null;
  }

  protected getEmptyConfig(): EmptyConfig {
    return null;
  }

  protected renderPleaseWait() {
    return <Box
      style={{display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center"}}>
      <Box style={{display: "flex", flexDirection: "column", alignItems: "center", gap: PD_SM}}>
        <CircularProgress/>
        <Typography>Please wait...</Typography>
      </Box>
    </Box>;
  }

  protected renderEmpty(): ReactElement {
    let emptyConfig = this.getEmptyConfig();
    if (!emptyConfig) {
      return null;
    }
    return <StyledEmpty emptyConfig={emptyConfig}/>;
  }

  protected getUnselectedConfig(): EmptyConfig {
    return null;
  }

  protected renderUnselected(): ReactElement {
    let unselectedConfig = this.getUnselectedConfig();
    if (!unselectedConfig) {
      return null;
    }
    return <StyledUnselected unselectedConfig={unselectedConfig}/>;
  }

  protected renderExtendedFab(action: Action, bottomMargin: number = 0) {
    const IconType = action.iconType;
    return <Fab variant="extended"
                color="secondary"
                style={{position: "fixed", right: 24, bottom: 24 + bottomMargin, padding: 24}}
                onClick={(event) => action.onClick(event)}>
      <IconType sx={{mr: 1}}/>
      {action.text}
    </Fab>

  }

  protected renderExtendedFabInFooterTabContainer(action: Action) {
    return this.renderExtendedFab(action, SZ_LG + BaseApp.CONTEXT.getAppConfig().safeAreaInsets?.bottom);
  }

  protected renderAccessoryMore(actions: Action[]): ReactElement {
    return <StyledButtonMore actions={actions}/>;
  }
}
