import {PathProps} from "../index";

export function parseHandle(pathProps: PathProps, handle?: any) {
  if (handle) {
    let handlePath: string = handle.path;
    let index;
    while ((index  = handlePath?.indexOf(":")) >= 0) {
      const start = index + 1;
      let end = handlePath.indexOf("/", start);
      if (end < 0) {
        end = handlePath.length;
      }
      handlePath = handlePath.substring(0, index) + pathProps.params[handlePath.substring(start, end)] + handlePath.substring(end);
      handle = {
        ...handle,
        path: handlePath,
      };
    }
  }
  return handle;
}
